import React from "react";
import Icons from "../ui/Icons";
import SignOut from "./SignOut";
import { useGetUserDetailsQuery } from "../../services/adminSlice";
import Loading from "../ui/Loading";
import ApiErrorModal from "../modals/ApiErrorModal";
import LoginUser from "./LoginUser";
import ViewAllNoticeModal from "../modals/ViewAllNoticeModal";
import ProfileDropdown from "./ProfileDropdown";
import { useLocation } from "react-router-dom";

const Headbar = () => {

  const { pathname } = useLocation()
  const response = useGetUserDetailsQuery();

  if (response.isLoading) return <Loading />;
  if (response.isError) return <ApiErrorModal response={response} />;

  if (pathname.startsWith("/singleSignOn")) {
    return <></>
  }

  return (
    <div className="bg-light p-3  shadow d-flex align-items-center justify-content-between bg-success shadow z-2">
      <div className="d-flex">
        <div
          className="btn bg-white rounded-circle  me-4 shadow z-3"
          data-bs-toggle="modal"
          data-bs-target={"#" + "viewAllNoticeModal"}
        >
          {Icons.notification()}
        </div>
        {/* <div className='px-3 bg-white rounded-pill shadow d-flex align-items-center'>
          <div className='me-2 fw-bold'>{response.data.name}{Icons.downArrow("ms-3")}</div>
        </div> */}
        <ProfileDropdown />
      </div>
      {/* <div className='d-flex gap-2'>
        <LoginUser />

        <SignOut />
      </div> */}

      <ViewAllNoticeModal />
    </div>
  );
};

export default Headbar;
