import React from 'react'
import Input from '../../components/form/Input'
import Icons from '../../components/ui/Icons'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import { useCreateCategoryMutation, useGetCategoriesQuery } from '../../services/categorySlice'
import { useCreatePolicyMutation, useGetPoliciesQuery } from '../../services/policySlice'
import PolicyDeleteModal from '../../components/modals/deleteModals/PolicyDeleteModal'
import { Link } from 'react-router-dom'
import CategoryDeleteModal from '../../components/modals/deleteModals/CategoryDeleteModal'
import EditCategoryNameModal from '../../components/modals/EditCategoryNameModal'
import Button from '../../components/form/Button'
import Slider from '../../components/Slider'
import FormattedInput from '../../components/form/FormattedInput'
import CreateCategoryModal from '../school/CreateCategoryModal'

const Hostel = () => {

  const rowClassName = 'p-3 bg-white rounded-4 my-3 d-flex justify-content-between align-items-center'

  const [createCategory, createCategoryRes] = useCreateCategoryMutation();
  const [createPolicy, createPolicyRes] = useCreatePolicyMutation()
  const categoryRes = useGetCategoriesQuery()
  const policyRes = useGetPoliciesQuery()


  const onCategorySubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    if (form.checkValidity()) {
      createCategory({
        title: event.target['Category Title'].value,
        type: "HOSTEL"
      })
        .then((res) => {
          if (!res.error) {
            categoryRes.refetch()
          }
        })
    }
    form.classList.add('was-validated')
  }

  const onPolicySubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    if (form.checkValidity()) {
      createPolicy({
        title: form['Policy Title'].value,
        content: form["Policy Content"].value,
        pdfLink: form['PDF Link'].value,
        enableDownload: form['downloadSwitch'].checked,
        enableText: form['testSwitch'].checked,
        testContent: form["Test Content"].value,
        categoryId: form['Category Select'].value,
        type: 'HOSTEL'
      })
        .then((res) => {
          if (!res.error) {
            policyRes.refetch()
          }
        })
    }
    form.classList.add('was-validated')
  }

  return (
    <div className='py-4'  style={{ overflowX: "hidden" }}>

      {/* {createCategoryRes.isSuccess && <SuccessModal message={"Category has been created."} />} */}
      {createCategoryRes.isError && <ApiErrorModal response={createCategoryRes} />}

      {/* {createPolicyRes.isSuccess && <SuccessModal message={"Policy has been created."} />} */}
      {createPolicyRes.isError && <ApiErrorModal response={createPolicyRes} />}


      <div className='row'>

        <div className='col-8 px-5'>
          <Slider type="Hostel" />

          <h3 className='fw-bold text-danger mb-2'>Hostel Policies</h3>
          <p className='mb-5'>the list of hostel polices to be followed by SGRS branches </p>

          {
            categoryRes.isSuccess && categoryRes.data.filter(item => item.type === "HOSTEL").map(item =>
              <div key={item.id}>
                <div className='d-flex align-items-center'>
                  <h5 className='fw-bold text-danger mb-2'>{item.title}</h5>
                  <EditCategoryNameModal id={item.id} refetch={categoryRes.refetch} refetch2={policyRes.refetch} />
                  <div><CategoryDeleteModal id={item.id} refetch={categoryRes.refetch} /></div>
                </div>

                <div className="">
                  <div className="row">
                    {
                      policyRes.isSuccess &&
                      policyRes.data.filter(item2 => (item2.category.title === item.title && item2.type === 'HOSTEL'))
                        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                        .map(item3 =>
                          <div className="col-md-6">
                            <div className={'p-3 bg-white rounded-4 my-3 d-flex justify-content-between align-items-center'}>
                              <p className='fw-bold w-75'>{item3.title}</p>
                              <div className='d-flex justify-content-between align-items-center'>
                                <PolicyDeleteModal id={item3.id} refetch={policyRes.refetch} />
                                <Link to={'edit/' + item3.id} className='me-3'>   {Icons.edit('', { width: 25, height: 25 })}</Link>
                                <Link to={'' + item3.id}>{Icons.arrow()}</Link>
                              </div>
                            </div>
                          </div>
                        )
                    }
                  </div>
                </div>



              </div>
            )
          }

        </div>


        <div className="col-4 position-relative p-0">


          <div className="mb-5">
            <div className="btn btn-primary px-5 rounded-bottom-4 position-absolute mx-4">
              Create new Policy
            </div>

            <form className="" onSubmit={onPolicySubmit} noValidate>
              <div className="bg-white rounded-4 px-5 pt-5">
                <Input name={"Policy Title"} containerClass={"mb-3"} required />

                <div className="d-flex align-items-center justify-content-between  mb-3">
                  <select
                    className="form-select flex-grow-1 me-3 rounded-pill"
                    aria-label="Category Select"
                    name="Category Select"
                    required
                  >
                    <option selected>Select Category</option>
                    {categoryRes.isSuccess &&
                      categoryRes.data
                        .filter((item) => item.type === "HOSTEL")
                        .map((item) => (
                          <option value={item.id}>{item.title}</option>
                        ))}
                  </select>
                  <CreateCategoryModal  type={"HOSTEL"} categoryRes={categoryRes} />
                </div>



                <FormattedInput name={"Policy Content"} />

                <Input name={"PDF Link"} containerClass={"mb-3"} required />

                <div className="form-check form-switch my-3">
                  <label className="form-check-label" htmlFor="downloadSwitch">
                    Enable download
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="downloadSwitch"
                    name="downloadSwitch"
                  />
                </div>

                <div className="form-check form-switch my-3">
                  <label className="form-check-label" htmlFor="testSwitch">
                    Enable test
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="testSwitch"
                    name="testSwitch"
                  />
                </div>

                <FormattedInput name={"Test Content"} />
                <Button
                  className="btn btn-primary px-5 rounded-pill my-4"
                  res={createPolicyRes}
                  loadingLabel={"Creating"}
                >
                  Post the Policy
                </Button>

                {/* <button type='submit' className="btn btn-primary px-5 rounded-pill my-4" style={{ marginTop: -20 }}>Post the Policy</button> */}
              </div>
            </form>
          </div>
        </div>

      </div>

    </div>

  )
}

export default Hostel
