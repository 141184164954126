import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCategories: builder.query({ query: () => `category`, keepUnusedDataFor: 0 }),
        getCategoryById: builder.query({ query: (id) => `category?id=${id}`, keepUnusedDataFor: 0 }),
        createCategory: builder.mutation({ query: (body) => ({ url: `category`, method: 'POST', body }) }),
        updateCategory: builder.mutation({ query: ({ id, body }) => ({ url: `category?id=${id}`, method: 'PATCH', body }) }),
        deleteCategory: builder.mutation({ query: (id) => ({ url: `category?id=${id}`, method: 'DELETE' }) }),
    })
})

export const { 
    useGetCategoriesQuery,
    useGetCategoryByIdQuery,
    useCreateCategoryMutation,
    useUpdateCategoryMutation,
    useDeleteCategoryMutation
 } = extendedApiSlice
