import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAuthToken } from "../../redux/authSlice";
import Loading from "../ui/Loading";
import ApiErrorModal from "../modals/ApiErrorModal";
import { useGetUserDetailsQuery } from "../../services/adminSlice";
import Api from "../../constants/Api";
import Image from "../ui/Image";
import Icons from "../ui/Icons";

const ProfileDropdown = () => {
  const dispatch = useDispatch();
  const res = useGetUserDetailsQuery();

  const handleSignOut = () => dispatch(setAuthToken(null));

  if (res.isLoading) return <Loading />;
  if (res.isError) return <ApiErrorModal response={res} />;

  return (
    <div className="dropdown">
      <button
        className="btn bg-white rounded-pill d-flex  align-items-center  px-3 shadow-sm dropdown-toggle "
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {
          // res.data.profilePicture.length > 0 &&
        
        }

        <Image
          src={Api?.PROFILE_PICTURE_URL + res?.data?.profilePicture?.id + res?.data?.profilePicture?.ext}
          alt="profile"
          className="rounded-circle fs-6 bg-white border border-5 border-white"
          style={{ height: 50, width: 50 }}
          icon={() => Icons.defaultUser("", { width: "100%", height: "100%" })}
        />



        {res.data.name}
      </button>
      <ul className="dropdown-menu">
        <li>
          <NavLink className="dropdown-item" to={"/settingV1"}>
            Settings
          </NavLink>
        </li>
        {res.data.brahmvidyaPortalAccess != null && (
          <li>
            <Link
              className="dropdown-item"
              to={`http://62.72.31.126:3003/token/${res.data.token}`}
              target="_blank"
            >
              Go to Brahmvidya Portal
            </Link>
          </li>
        )}

        <li>
          <button className="dropdown-item" onClick={handleSignOut}>
            Sign Out
          </button>
        </li>
      </ul>
    </div>
  );
};

export default ProfileDropdown;
