import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPolicies: builder.query({ query: () => `policy`, keepUnusedDataFor: 0 }),
        getPolicyById: builder.query({ query: (id) => `policy?id=${id}`, keepUnusedDataFor: 0 }),
        createPolicy: builder.mutation({ query: (body) => ({ url: `policy`, method: 'POST', body }) }),
        updatePolicy: builder.mutation({ query: ({ id, body }) => ({ url: `policy?id=${id}`, method: 'PATCH', body }) }),
        deletePolicy: builder.mutation({ query: (id) => ({ url: `policy?id=${id}`, method: 'DELETE' }) }),
    })
})

export const {
    useGetPoliciesQuery,
    useGetPolicyByIdQuery,
    useCreatePolicyMutation,
    useUpdatePolicyMutation,
    useDeletePolicyMutation
} = extendedApiSlice
