import React from 'react'
import pic from '../../assets/images/pic.png'
import { Link } from 'react-router-dom'
import { useGetArchivesQuery } from '../../services/archiveSlice'
import Loading from '../../components/ui/Loading'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import ArchiveDeleteModal from '../../components/modals/deleteModals/ArchiveDeleteModal'
import Api from '../../constants/Api'
import ViewArchiveModal from './ViewArchiveModal'

const Archives = () => {

  const response = useGetArchivesQuery()

  if (response.isLoading) return <Loading />
  if (response.isError) return <ApiErrorModal response={response} />

  return (
    <div className='p-5'>

      <div className='d-flex justify-content-between align-items-center'>
        <h2 className='fw-bold mb-3 text-danger'>Educational  Archives</h2>
        <Link to={'Create'} className="btn bg-danger text-white rounded-pill px-5">Create new Archive</Link>
      </div>

      <p className='mb-5'>This all are the latest updates on our school</p>

      <div className="row row-cols-2 g-3">




        {response.isSuccess && response.data.map((item, i) => (
          <div className='col' key={i}>
            <div className='d-flex bg-white rounded-4 p-3' style={{ height: '200px' }}> {/* Set a fixed height */}
              <div className='me-3'>
                <h6 className='fw-bold text-danger mb-3'>{item.title}</h6>
                <p className='mb-3' dangerouslySetInnerHTML={{ __html: `${item.content}` }}></p>
                <div className='d-flex gap-3'>
                  <ViewArchiveModal item={item} />
                  <Link className="btn btn-primary px-4 rounded-pill" to={'' + item.id}>Edit</Link>
                  <ArchiveDeleteModal id={item.id} refetch={response.refetch} />
                </div>
              </div>
              <div className="w-50 d-flex align-items-center justify-content-center"> {/* Adjust the width as needed */}
                {item.images.length > 0 &&
                  <img className='w-100 h-100 rounded' src={Api.BASE_URL + "uploads/images/" + item.images[0].id + item.images[0].ext} alt="" />
                }
              </div>
            </div>
          </div>
        ))}


      </div>

    </div>
  )
}

export default Archives
