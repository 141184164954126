import React, { useState } from "react";
import Icons from "../../components/ui/Icons";
import { useGetDownloadablesQuery } from "../../services/downloadablesSlice";
import { Link } from "react-router-dom";
import Loading from "../../components/ui/Loading";
import ApiErrorModal from "../../components/modals/ApiErrorModal";
import DownloadableDeleteModal from "../../components/modals/deleteModals/DownloadableDeleteModal";
import CreateDownloadables from "./CreateDownloadables";
import downArrow from "../../assets/images/downarrow.png";
import ViewEditInfoModal from "../../components/modals/ViewEditInfoModal";

const Downloadables = () => {
  const [selectedTab, setSelectedTab] = useState("document");

  const response = useGetDownloadablesQuery();

  const iconClassName = (tab) => {
    switch (tab) {
      case "document":
        return "p-3 rounded-3 me-4 cursor-pointer";
      case "video":
        return "p-3 rounded-3 me-4 cursor-pointer";
      case "audio":
        return "p-3 rounded-3 me-4 cursor-pointer";
      case "picture":
        return "p-3 rounded-3 me-4 cursor-pointer";

      default:
        break;
    }
  };

  const iconBackground = (tab) => {
    switch (tab) {
      case "document":
        return "#2A73C5";
      case "video":
        return "#F5BF03";
      case "audio":
        return "#EE38CB";
      case "picture":
        return "#78AD46";

      default:
        return "red";
    }
  };

  const iconStyle = (tab) => {
    switch (tab) {
      case "document":
        return "#2A73C5";
      case "video":
        return "#F5BF03";
      case "audio":
        return "#EE38CB";
      case "picture":
        return "#78AD46";

      default:
        return "red";
    }
  };

  if (response.isLoading) return <Loading />;
  if (response.isError) return <ApiErrorModal response={response} />;

  return (
    <div className="h-100 container-fluid ">
      <div className="row ps-2 overflow-hidden hide-scroll h-100">
        <div className="py-4 col-8 px-4  overflow-hidden hide-scroll h-100  d-flex flex-column">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="fw-bold mb-3 text-danger">Downloadables</h2>
          </div>

          <div className="d-flex mb-5">
            <div className="text-center me-4 position-relative cursor-pointer">
              <div
                className="p-3 rounded-3"
                style={{ backgroundColor: "#2A73C5" }}
                onClick={() => setSelectedTab("document")}
              >
                {Icons.document("", { width: 25, height: 25 })}
              </div>
              {selectedTab === "document" && (
                <img src={downArrow} alt="" width={20} />
              )}
            </div>

            <div className="text-center me-4 position-relative cursor-pointer">
              <div
                className="p-3 rounded-3"
                style={{ backgroundColor: "#F5BF03" }}
                onClick={() => setSelectedTab("video")}
              >
                {Icons.video("", { width: 25, height: 25 })}
              </div>
              {selectedTab === "video" && (
                <img src={downArrow} alt="" width={20} />
              )}
            </div>

            <div className="text-center me-4 position-relative cursor-pointer">
              <div
                className="p-3 rounded-3"
                style={{ backgroundColor: "#EE38CB" }}
                onClick={() => setSelectedTab("audio")}
              >
                {Icons.audio("", { width: 25, height: 25 })}
              </div>
              {selectedTab === "audio" && (
                <img src={downArrow} alt="" width={20} />
              )}
            </div>

            <div className="text-center me-4 position-relative cursor-pointer">
              <div
                className="p-3 rounded-3"
                style={{ backgroundColor: "#78AD46" }}
                onClick={() => setSelectedTab("picture")}
              >
                {Icons.picture("", { width: 25, height: 25 })}
              </div>
              {selectedTab === "picture" && (
                <img src={downArrow} alt="" width={20} />
              )}
            </div>
          </div>

          <div className="overflow-auto flex-grow-1 ">
            <div className="row row-cols-3 gy-3 ">
              {response.data
                .filter((item) => item.type === selectedTab)
                .map((item, i) => (
                  <div className="col" key={i}>
                    <div
                      className="d-flex align-items-center bg-white rounded-4 cursor-pointer shadow p-2"
                      data-bs-toggle="modal"
                      data-bs-target={"#" + "viewEditInfoModal" + item.id}
                    >
                      {/* onClick={() => window.open(item.link, '_blank')} */}
                      <div
                        className={iconClassName(selectedTab)}
                        style={{ backgroundColor: iconStyle(selectedTab) }}
                      >
                        {Icons[selectedTab]("", { width: 25, height: 25 })}
                      </div>
                      <div className="d-flex flex-column">
                        <p
                          id={"viewEditInfoModal" + item.id + "CloseBtn"}
                          className="fw-bold"
                        >
                          {item.name}
                        </p>
                        <p className="text-muted">{item.type}</p>
                        <div className="align-self-end">
                          {/* <Link className='btn' to={'' + item.id}>
                      {Icons.edit()}
                    </Link> */}
                          <ViewEditInfoModal
                            refetch={response.refetch}
                            id={item.id}
                            isCreate={false}
                          />
                          {/* <DownloadableDeleteModal id={item.id} refetch={response.refetch} /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className="col-4  px-0 ps-3 py-4 h-100">
          <CreateDownloadables
            data={response.data}
            refetch={response.refetch}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="py-4 d-flex h-100">
      <div className="w-75 px-5 d-flex flex-column hide-scroll overflow-hidden">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="fw-bold mb-3 text-danger">Downloadables</h2>
        </div>

        <div className="d-flex mb-5">
          <div className="text-center me-4 position-relative">
            <div
              className="p-3 rounded-3"
              style={{ backgroundColor: "#2A73C5" }}
              onClick={() => setSelectedTab("document")}
            >
              {Icons.document("", { width: 25, height: 25 })}
            </div>
            {selectedTab === "document" && (
              <img src={downArrow} alt="" width={20} />
            )}
          </div>

          <div className="text-center me-4 position-relative">
            <div
              className="p-3 rounded-3"
              style={{ backgroundColor: "#F5BF03" }}
              onClick={() => setSelectedTab("video")}
            >
              {Icons.video("", { width: 25, height: 25 })}
            </div>
            {selectedTab === "video" && (
              <img src={downArrow} alt="" width={20} />
            )}
          </div>

          <div className="text-center me-4 position-relative">
            <div
              className="p-3 rounded-3"
              style={{ backgroundColor: "#EE38CB" }}
              onClick={() => setSelectedTab("audio")}
            >
              {Icons.audio("", { width: 25, height: 25 })}
            </div>
            {selectedTab === "audio" && (
              <img src={downArrow} alt="" width={20} />
            )}
          </div>

          <div className="text-center me-4 position-relative">
            <div
              className="p-3 rounded-3"
              style={{ backgroundColor: "#78AD46" }}
              onClick={() => setSelectedTab("picture")}
            >
              {Icons.picture("", { width: 25, height: 25 })}
            </div>
            {selectedTab === "picture" && (
              <img src={downArrow} alt="" width={20} />
            )}
          </div>
        </div>

        <div className="container overflow-auto flex-grow-1 ">
          <div className="row row-cols-3 gy-5 ">
            {response.data
              .filter((item) => item.type === selectedTab)
              .map((item) => (
                <div className="col">
                  <div className="d-flex align-items-center">
                    <div
                      className={iconClassName(selectedTab)}
                      style={{ backgroundColor: iconBackground(selectedTab) }}
                      onClick={() => window.open(item.link, "_blank")}
                    >
                      {Icons[selectedTab]("", { width: 25, height: 25 })}
                    </div>
                    <div className="">
                      <p className="fw-bold">{item.name}</p>
                      <p className="text-muted">{item.type}</p>
                      <div className="align-self-end">
                        <Link className="btn" to={"" + item.id}>
                          {Icons.edit()}
                        </Link>
                        <DownloadableDeleteModal
                          id={item.id}
                          refetch={response.refetch}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {response.data
              .filter((item) => item.type === selectedTab)
              .map((item) => (
                <div className="col">
                  <div className="d-flex align-items-center">
                    <div
                      className={iconClassName(selectedTab)}
                      style={{ backgroundColor: iconBackground(selectedTab) }}
                      onClick={() => window.open(item.link, "_blank")}
                    >
                      {Icons[selectedTab]("", { width: 25, height: 25 })}
                    </div>
                    <div className="">
                      <p className="fw-bold">{item.name}</p>
                      <p className="text-muted">{item.type}</p>
                      <div className="align-self-end">
                        <Link className="btn" to={"" + item.id}>
                          {Icons.edit()}
                        </Link>
                        <DownloadableDeleteModal
                          id={item.id}
                          refetch={response.refetch}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {response.data
              .filter((item) => item.type === selectedTab)
              .map((item) => (
                <div className="col">
                  <div className="d-flex align-items-center">
                    <div
                      className={iconClassName(selectedTab)}
                      style={{ backgroundColor: iconBackground(selectedTab) }}
                      onClick={() => window.open(item.link, "_blank")}
                    >
                      {Icons[selectedTab]("", { width: 25, height: 25 })}
                    </div>
                    <div className="">
                      <p className="fw-bold">{item.name}</p>
                      <p className="text-muted">{item.type}</p>
                      <div className="align-self-end">
                        <Link className="btn" to={"" + item.id}>
                          {Icons.edit()}
                        </Link>
                        <DownloadableDeleteModal
                          id={item.id}
                          refetch={response.refetch}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {response.data
              .filter((item) => item.type === selectedTab)
              .map((item) => (
                <div className="col">
                  <div className="d-flex align-items-center">
                    <div
                      className={iconClassName(selectedTab)}
                      style={{ backgroundColor: iconBackground(selectedTab) }}
                      onClick={() => window.open(item.link, "_blank")}
                    >
                      {Icons[selectedTab]("", { width: 25, height: 25 })}
                    </div>
                    <div className="">
                      <p className="fw-bold">{item.name}</p>
                      <p className="text-muted">{item.type}</p>
                      <div className="align-self-end">
                        <Link className="btn" to={"" + item.id}>
                          {Icons.edit()}
                        </Link>
                        <DownloadableDeleteModal
                          id={item.id}
                          refetch={response.refetch}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="w-25">
        <CreateDownloadables data={response.data} />
      </div>
    </div>
  );

  //brahmavidhya admin dashboard
  return (
    <div className="py-5 d-flex">
      <div className="w-75 px-5 overflow-hidden">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="fw-bold mb-3 text-danger">Downloadables</h2>
        </div>

        <div className="d-flex">
          <div className="text-center me-4 position-relative">
            <div
              className="p-3 rounded-3"
              style={{ backgroundColor: "#2A73C5" }}
              onClick={() => setSelectedTab("document")}
            >
              {Icons.document("", { width: 25, height: 25 })}
            </div>
            {selectedTab === "document" && (
              <img src={downArrow} alt="" width={20} />
            )}
          </div>

          <div className="text-center me-4 position-relative">
            <div
              className="p-3 rounded-3"
              style={{ backgroundColor: "#F5BF03" }}
              onClick={() => setSelectedTab("video")}
            >
              {Icons.video("", { width: 25, height: 25 })}
            </div>
            {selectedTab === "video" && (
              <img src={downArrow} alt="" width={20} />
            )}
          </div>

          <div className="text-center me-4 position-relative">
            <div
              className="p-3 rounded-3"
              style={{ backgroundColor: "#EE38CB" }}
              onClick={() => setSelectedTab("audio")}
            >
              {Icons.audio("", { width: 25, height: 25 })}
            </div>
            {selectedTab === "audio" && (
              <img src={downArrow} alt="" width={20} />
            )}
          </div>

          <div className="text-center me-4 position-relative">
            <div
              className="p-3 rounded-3"
              style={{ backgroundColor: "#78AD46" }}
              onClick={() => setSelectedTab("picture")}
            >
              {Icons.picture("", { width: 25, height: 25 })}
            </div>
            {selectedTab === "picture" && (
              <img src={downArrow} alt="" width={20} />
            )}
          </div>
        </div>

        <div className="row row-cols-3 gy-5 my-3 overflow-auto">
          {response.data
            .filter((item) => item.type === selectedTab)
            .map((item) => (
              <div key={item.id} className="col">
                <div className="d-flex align-items-center">
                  <div
                    className={iconClassName(selectedTab)}
                    style={{ backgroundColor: iconBackground(selectedTab) }}
                    onClick={() => window.open(item.link, "_blank")}
                  >
                    {Icons[selectedTab]("", { width: 25, height: 25 })}
                  </div>
                  <div className="d-flex flex-column">
                    <p className="fw-bold">{item.name}</p>
                    <p className="text-muted">{item.type}</p>
                    <div className="align-self-end">
                      <Link className="btn" to={"" + item.id}>
                        {Icons.edit()}
                      </Link>
                      <DownloadableDeleteModal
                        id={item.id}
                        refetch={response.refetch}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className="w-25 bg-danger">
        <CreateDownloadables data={response.data} />
      </div>
    </div>
  );
};

export default Downloadables;
