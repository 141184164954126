import React from 'react'
import '@splidejs/splide/css';
import Splide from '@splidejs/splide';
import { useEffect } from 'react';
import SliderEditModal from './SliderEditModal';
import { useGetSliderQuery } from '../services/sliderSlice';
import Loading from './ui/Loading';
import ApiErrorModal from './modals/ApiErrorModal';
import Api from '../constants/Api';

const Slider = ({ type }) => {

    const sliderRes = useGetSliderQuery()
    let splide



    useEffect(() => {
        if (sliderRes.isSuccess) {
            splide = new Splide('.splide', {
                type: 'slide',
                arrows: false,
                rewind: true,
                autoplay: true,
                interval: 5000
            });
            splide.mount();
            return () => { splide?.destroy() }
        }
    }, [sliderRes])

    if (sliderRes.isLoading) return <Loading />
    if (sliderRes.isError) return <ApiErrorModal response={sliderRes} />

    const images = sliderRes.data.filter(i => i.type === type).map(i => Api.SLIDER_URL + i.id + i.ext)

    return (
        <section className="splide position-relative mb-3">
            <SliderEditModal images={images} type={type} />
            <div className="splide__slider rounded-5 overflow-hidden shadow border">
                <div className="splide__track">
                    <ul className="splide__list">
                        {images.map((src) => <li className="splide__slide"><img src={src} className='w-100' alt="" /></li>)}
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Slider