import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import srgs from '../../assets/images/srgs.png'
import sucess from '../../assets/images/success.png'

const SuccessModal = ({ label, message, to, closeTo }) => {

    const navigate = useNavigate();
    const onClose = () => closeTo && navigate(closeTo)

    useEffect(() => {
        document.getElementById("successComponentModalButton")?.click()
    }, [])

    return (
        <>
            <button type="button" id="successComponentModalButton" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#successComponentModal">
                Launch demo modal
            </button>

            <div className="modal fade text-dark" id="successComponentModal" tabIndex="-1" aria-labelledby="successComponentModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className='modal-content bg-white rounded-4'>
                        <div className="alert alert-success mb-0 border border-5 border-success rounded-4 ">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onClose}></button>
                            </div>
                            <div className="modal-body text-center text-dark">
                                <div className="mb-3">
                                    <img className="mb-3" src={srgs} alt="Danger" width={125} />
                                    <h4>SGRS Utility digital assistance</h4>
                                </div>
                                <img className="" src={sucess} alt="Danger" width={100} />
                                <h1 className="modal-title" id="successComponentModalLabel">{label}</h1>
                                <p className='mb-0'>{message}</p>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-light rounded-pill px-4 shadow-sm" data-bs-dismiss="modal" onClick={onClose}>Close</button>
                                {
                                    to &&
                                    <button type="button" className="btn btn-success rounded-pill px-4 shadow-sm" data-bs-dismiss="modal" onClick={() => navigate(to.link)}>{to.text}</button>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SuccessModal