import React from 'react'
import { useGetNoticeByIdQuery, useUpdateNoticeMutation } from '../../services/noticeSlice'
import { useParams } from 'react-router-dom'
import Input from '../../components/form/Input'
import SuccessModal from '../../components/modals/SuccessModal'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import Loading from '../../components/ui/Loading'
import FormattedInput from '../../components/form/FormattedInput'

const EditNotice = () => {

  const { id } = useParams()

  const response = useGetNoticeByIdQuery(id)
  const [updateNotice, updateNoticeRes] = useUpdateNoticeMutation()

  const onSubmit = (e) => {
    e.preventDefault()

    const form = e.target;

    if (form.checkValidity()) {
      const body = {
        title: form['Title'].value,
        date: form['Date'].value,
        content: form['Content'].value,
      }

      updateNotice({ id, body })
        .then((res) => {
          if (!res.error) {
            form.reset()
            form.classList.remove('was-validated')
          }
        })
    }
    else form.classList.add('was-validated')
  }

  if (response.isLoading) return <Loading />
  if (response.isError) return <ApiErrorModal response={response} />

  return (
    <div className='p-5'>

      {updateNoticeRes.isSuccess && <SuccessModal label={"Updated!"} message={"Notice has been updated."} to={{ link: "/", text: "Go to Dashboard" }} closeTo={"/"} />}
      {updateNoticeRes.isError && <ApiErrorModal response={updateNoticeRes} />}

      <div>
        <form className="" onSubmit={onSubmit} noValidate>
          <Input name={'Title'} containerClass={'mb-3'} defaultValue={response.data.title} required />
          <Input name={'Date'} type={'date'} containerClass={'mb-3'} defaultValue={response.data.date} required />
          <FormattedInput name={"Content"} defaultValue={response.data.content} />
          <button className="btn btn-primary px-5 rounded-pill text-white my-3">Publish</button>
        </form>
      </div>
    </div>
  )
}

export default EditNotice