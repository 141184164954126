import React from 'react'
import Input from '../../components/form/Input'
import Select from '../../components/form/Select'
import { useCreateDownloadableMutation } from '../../services/downloadablesSlice'
import SuccessModal from '../../components/modals/SuccessModal'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import Button from '../../components/form/Button'

const CreateDownloadables = ({ data }) => {

    const type = ["document", "video", "audio", "picture"]
    const containerClass = "mb-3"

    const [createDownloadable, createDownloadableRes] = useCreateDownloadableMutation();

    const onSubmit = (event) => {
        event.preventDefault();
        const form = event.target;
        if (form.checkValidity()) {

            createDownloadable({
                name: event.target['File Name'].value,
                type: event.target['File Type'].value,
                link: event.target['File Link'].value
            })
        }

        form.classList.add('was-validated')
    }

    return (
        <div className='px-4 text-center bg-white rounded-start-5 shadow h-100'>

            {createDownloadableRes.isSuccess && <SuccessModal label={"Added!"} message={"Downloadable has been added."} to={{ link: "/downloadables", text: "Go to Downloadables" }} closeTo={"/downloadables"} />}
            {createDownloadableRes.isError && <ApiErrorModal response={createDownloadableRes} />}

            {/* <div className='overflow-hidden'><span className='bg-primary text-white rounded-bottom-4 py-3'>Add Downloadable</span></div> */}
            <div className="d-flex justify-content-center align-items-start mb-3">
                <div className="text-white shadow text-center bg-primary rounded-bottom-4 px-4 py-2 ">
                    Add Downloadable
                </div>
            </div>

            <form className="" onSubmit={onSubmit} noValidate>
                <Input name={"File Name"} inputClass={'bg-white'} containerClass={containerClass} required />
                <Select name={"File Type"} options={type} inputClass={'bg-white'} containerClass={containerClass} />
                <Input name={"File Link"} inputClass={'bg-white'} containerClass={containerClass} required />
                <Button
                    className="btn bg-danger text-white rounded-pill px-5"
                    res={createDownloadableRes}
                    loadingLabel={"Adding..."}
                >
                    Add New Downloadable
                </Button>
            </form>

            <hr />

            <div className="px-3 pt-3 mb-2 mt-3 container">
                <div className="row mb-3 px-5">
                    <div className="col">
                        <div className='bg-white text-center shadow p-3 fs-13  h-100 '>
                            <div className='fs-6 my-2'>Downloadable Insights</div>
                        </div>
                        <div className='' style={{ marginTop: -9, height: 9 , backgroundColor: "#00ADAD" }}></div>
                    </div>
                    {/* <div className="col">
                        <div className='bg-white shadow mb-3 p-3 fw-bold'>Downloadable Insights</div>
                    </div>
                    <div className='bg-info' style={{ marginTop: -9, height: 9 }}></div> */}
                </div>

                <div className="row row-cols-2 justify-content-center g-3">

                    <div className="col">
                        <div className='bg-white text-center shadow p-3 fs-13  h-100 '>
                            <div className='fw-bold fs-5'>{data.filter(item => item.type === "document").length}</div>
                            <div className='fs-6'>Downloadable Documents</div>
                        </div>
                        <div className='' style={{ marginTop: -9, height: 9 , backgroundColor: "#78AD46"}}></div>
                    </div>

                    <div className="col">
                        <div className='bg-white text-center shadow p-3 fs-13  h-100 '>
                            <div className='fw-bold fs-5'>{data.filter(item => item.type === "video").length}</div>
                            <div className='fs-6'>Downloadable <br /> Videos</div>
                        </div>
                        <div className='bg-warning' style={{ marginTop: -9, height: 9 }}></div>

                    </div>

                    <div className="col">
                        <div className='bg-white text-center shadow p-3 fs-13  h-100 '>
                            <div className='fw-bold fs-5 '>{data.filter(item => item.type === "audio").length}</div>
                            <div className='fs-6'>Downloadable <br /> Audios</div>
                        </div>
                        <div className='' style={{ marginTop: -9, height: 9, backgroundColor: "#EE38CB" }}></div>
                    </div>
                    <div className="col">
                        <div className='bg-white text-center shadow p-3 fs-13  h-100 '>
                            <div className='fw-bold fs-5'>{data.filter(item => item.type === "picture").length}</div>
                            <div className='fs-6'>Downloadable <br />Images</div>
                        </div>
                        <div className='bg-danger' style={{ marginTop: -9, height: 9 }}></div>
                    </div>

                </div>
            </div>

            {/* <div className='px-3 d-flex flex-column align-items-center'>
                <div className='bg-white shadow mb-3 p-3 fs-13 border-5 border-bottom border-success'>Downloadable Insights</div>
                <div className='d-flex mb-3'>
                    <div className='bg-white shadow me-3 p-3 fs-13 border-5 border-bottom border-danger'>
                        <div className='fw-bold'>{data.filter(item => item.type === "document").length}</div>
                        <div>Downloadable Documents</div>
                    </div>
                    <div className='bg-white shadow p-3 fs-13 border-5 border-bottom border-warning'>
                        <div className='fw-bold'>{data.filter(item => item.type === "video").length}</div>
                        <div>Downloadable Videos</div>
                    </div>
                </div>
                <div className='d-flex mb-3'>
                    <div className='bg-white shadow me-3 p-3 fs-13 border-5 border-bottom border-primary'>
                        <div className='fw-bold'>{data.filter(item => item.type === "audio").length}</div>
                        <div>Downloadable Audios</div>
                    </div>
                    <div className='bg-white shadow p-3 fs-13  border-5 border-bottom border-secondary'>
                        <div className='fw-bold'>{data.filter(item => item.type === "picture").length}</div>
                        <div>Downloadable Images</div>
                    </div>
                </div>
            </div> */}

        </div >
    )
}

export default CreateDownloadables