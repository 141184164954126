// import React, { useState } from 'react'
// import Input from '../../components/form/Input'
// import userImg from '../../assets/images/userPhoto.png'
// import Icons from '../../components/ui/Icons'
// import { useChangePasswordMutation } from '../../services/authSlice'
// import SuccessModal from '../../components/modals/SuccessModal'
// import ApiErrorModal from '../../components/modals/ApiErrorModal'
// import { useCreateTechSupportMutation, useGetTechSupportQuery, useGetUserDetailsQuery, useUpdateTechSupportMutation } from '../../services/adminSlice'
// import Loading from '../../components/ui/Loading'

// const Settings = () => {

//     const containerClass = "col mb-4"
//     const [selectedTab, setSelectedTab] = useState("Primary Details")
//     const activeTabClassName = "border-start border-5 border-primary p-3 fw-bold";
//     const inactiveTabClassName = "p-3 fw-bold";

//     const [changePassword, changePasswordRes] = useChangePasswordMutation()
//     const getTech = useGetTechSupportQuery()
//     const [createTech, createTechRes] = useCreateTechSupportMutation()
//     const [updateTech, updateTechRes] = useUpdateTechSupportMutation()
//     const response = useGetUserDetailsQuery()


//     const changePassOnSubmit = (e) => {
//         e.preventDefault()

//         const form = e.target;

//         if (form.checkValidity()) {

//             const body = {
//                 userName: form['User Id'].value,
//                 oldPassword: form['Old Password'].value,
//                 newPassword: form['New Password'].value,
//             }

//             changePassword(body)
//                 .then((res) => {
//                     if (!res.error) {
//                         form.reset()
//                         form.classList.remove('was-validated')
//                     }
//                 })

//         }
//         else form.classList.add('was-validated')

//     }

//     const handleTechSubmit = (e) => {
//         e.preventDefault()
//         const body = {
//             phone: e.target['Phone'].value,
//             email: e.target['Email'].value
//         }
//         createTech(body)
//             .then(() => {

//                 getTech.refetch()

//             })
//     }

//     const handleTechSubmitUpdate = (e) => {
//         e.preventDefault()
//         let id = e.target['Id'].value;
//         const body = {
//             phone: e.target['Phone'].value,
//             email: e.target['Email'].value
//         }
//         updateTech({ id, body })
//             .then(() => {

//                 getTech.refetch()

//             })
//     }

//     if (getTech.isLoading) return <Loading />

//     return (
//         <div className='py-5 d-flex h-100 justify-content-between align-items-center'>

//             {changePasswordRes.isSuccess && <SuccessModal label={"Password Changed!"} message={"Password has been changed."} closeTo={"/settings"} />}
//             {changePasswordRes.isError && <ApiErrorModal response={changePasswordRes} />}

//             <div className='my-5 mx-2 d-flex h-100 align-items-center'>

//                 <div className='bg-white rounded-start-4 rounded-end-5 shadow overflow-hidden' style={{ zIndex: 50, width: 300 }}>
//                     <h3 className='fw-bold text-danger mb-4 p-4' style={{ backgroundColor: "#F5F5F5" }}>Settings</h3>
//                     <div className={selectedTab === "Primary Details" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Primary Details")}>Primary Details</div>
//                     <div className={selectedTab === "Contact Details" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Contact Details")}>Contact Details</div>
//                     <div className={selectedTab === "Login ID & Password" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Login ID & Password")}>Login ID & Password</div>
//                     <div className={selectedTab === "2FA" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("2FA")}>2 Factor Authentication (2FA)</div>
//                     <hr className='mx-4' />
//                     <div className='p-4'>
//                         <div className='d-flex align-items-center mb-4'>
//                             <div className='fw-bold'>{Icons.gear('fs-2')}</div>
//                             <div className='ms-3'>for technical support</div>
//                         </div>


//                         <div className='d-flex flex-column gap-2'>
//                             {
//                                 getTech?.data?.length > 0 ?
//                                     <form onSubmit={handleTechSubmitUpdate}>
//                                         <Input type={'number'} name={'Phone'} labelName={'Call Us'} defaultValue={getTech?.data[0]?.phone} />
//                                         <Input type={'text'} name={'Email'} labelName={'Email Us'} defaultValue={getTech?.data[0]?.email} />
//                                         <Input type={'hidden'} name={'Id'} defaultValue={getTech?.data[0]?.id} />
//                                         <button type='submit' className='btn btn-primary'>Update</button>
//                                     </form>
//                                     :
//                                     <form onSubmit={handleTechSubmit}>
//                                         <Input type={'number'} name={'Phone'} labelName={'Call Us'} defaultValue={getTech?.data[0]?.phone} />
//                                         <Input type={'text'} name={'Email'} labelName={'Email Us'} defaultValue={getTech?.data[0]?.email} />

//                                         <button type='submit' className='btn btn-primary'>Create</button>
//                                     </form>
//                             }

//                         </div>
//                     </div>
//                 </div>

//                 {selectedTab === "Primary Details" &&
//                     <div className='p-5 rounded-end-5 shadow mt-5 bg-white w-75'>
//                         <div className='d-flex align-items-center mb-3'>
//                             <img src={userImg} alt="" className='mx-3' width={50} />
//                             <div className='mt-3 mb-4 me-5'>
//                                 <p className='fw-bold'>Janmangal Swami</p>
//                                 <p className='fs-11'>Post</p>
//                             </div>
//                         </div>

//                         <Input name={"Name"} containerClass={containerClass} required />
//                         <Input name={"Branch"} containerClass={containerClass} required />
//                         <Input name={"Post"} containerClass={containerClass} required />
//                         <Input name={"S-UID"} containerClass={containerClass} required />

//                         <p className='fs-11'>To update your primary details, kindly get in touch with the SGRS Utility Service Provider
//                             or reach out to our dedicated technical support team for assistance.</p>
//                     </div>
//                 }

//                 {selectedTab === "Contact Details" &&
//                     <div className='p-5 rounded-end-5 shadow mt-5 bg-white w-75'>
//                         <div className='d-flex align-items-center mb-3'>
//                             <img src={userImg} alt="" className='mx-3' width={50} />
//                             <div className='mt-3 mb-4 me-5'>
//                                 <p className='fw-bold'>Janmangal Swami</p>
//                                 <p className='fs-11'>Post</p>
//                             </div>
//                         </div>
//                         <Input name={"Email"} containerClass={containerClass} required />
//                         <p className='fs-11 mb-5'>(All crucial communications will be conveyed through this designated contact number.)</p>
//                         <Input name={"Contact Number"} containerClass={containerClass} required />
//                         <p className='fs-11 mb-5'>(This email will serve as the primary means for communicating important details
//                             and updates to enhance your overall experience.)</p>

//                         <div className="btn btn-sm rounded-pill text-white px-5 bg-primary">Save Settings</div>
//                     </div>
//                 }

//                 {selectedTab === "Login ID & Password" &&
//                     <div className='p-5 rounded-end-5 shadow mt-5 bg-white w-75'>
//                         <div className='d-flex align-items-center mb-3'>
//                             <img src={userImg} alt="" className='mx-3' width={50} />
//                             <div className='mt-3 mb-4 me-5'>
//                                 <p className='fw-bold'>Janmangal Swami</p>
//                                 <p className='fs-11'>Post</p>
//                             </div>
//                         </div>
//                         <form className="" onSubmit={changePassOnSubmit} noValidate>
//                             <Input name={"Email"} containerClass={containerClass} required />
//                             <p className='fs-11 mb-5'>If you suspect that your user ID is at risk of unauthorized access,
//                                 please promptly contact the SGRS Utility Service Administrator to update your user ID.
//                                 Failure to uphold account security measures may result in temporary or permanent suspension from all Utility services.
//                                 Please refer to the terms and conditions for further information.</p>
//                             <p className='fs-11 mb-3'>To update your ID and password, enter your current password,
//                                 input your new password, undergo authentication via 2FA,
//                                 and then proceed to change your password.</p>
//                             <Input name={"Old Password"} containerClass={containerClass} required />
//                             <Input name={"New Password"} containerClass={containerClass} required />
//                             {/* <Input name={"2FA Code (Email)"} containerClass={containerClass} required /> */}
//                             <button className="btn btn-sm rounded-pill text-white px-5 bg-primary">Save Settings</button>
//                         </form>
//                     </div>
//                 }

//                 {selectedTab === "2FA" &&
//                     <div className='p-5 rounded-end-5 shadow mt-5 bg-white w-75'>
//                         <div className='d-flex align-items-center mb-3'>
//                             <img src={userImg} alt="" className='mx-3' width={50} />
//                             <div className='mt-3 mb-4 me-5'>
//                                 <p className='fw-bold'>Janmangal Swami</p>
//                                 <p className='fs-11'>Post</p>
//                             </div>
//                         </div>
//                         <Input name={"Email"} containerClass={containerClass} required />
//                         <p className='fs-11 mb-5'>(This email will serve as your secondary factor for authentication.)</p>
//                         <Input name={"Contact Number"} containerClass={containerClass} required />
//                         <p className='fs-11 mb-3'>(This contact number will be employed as your secondary factor for authentication.
//                             Please note that 2FA via contact number is currently unavailable.)</p>
//                         <div className="btn btn-sm rounded-pill text-white px-5 bg-primary">Save Settings</div>
//                     </div>
//                 }

//             </div>

//             <div className='py-5 h-100'>
//                 <div className='d-flex flex-column h-100 overflow-hidden rounded-start-5 shadow' style={{ width: 300 }}>
//                     <div className='bg-info  p-3 d-flex justify-content-between px-3 align-items-center '>
//                         <div className='fw-bold text-danger'>Terms and Conditions</div>
//                         <div className='rounded-4 bg-primary btn btn-sm text-white px-3 py-1'>Edit</div>
//                     </div>
//                     <div className='flex-grow-1 bg-white'></div>
//                     <div className='text-center bg-info  fw-bold p-3'>Accepted <span className='text-dark fs-11'>On 23rd Jan; 2023 (12:30 IST)</span></div>
//                 </div>
//             </div>

//         </div>
//     )

//     return (
//         <div className='p-5 d-flex justify-content-center'>

//             {changePasswordRes.isSuccess && <SuccessModal label={"Password Changed!"} message={"Password has been changed."} closeTo={"/settings"} />}
//             {changePasswordRes.isError && <ApiErrorModal response={changePasswordRes} />}

//             <div className='m-4 d-flex'>

//                 <div className='bg-white rounded-start-4 rounded-end-5 shadow overflow-hidden' style={{ zIndex: 50 }}>
//                     <h3 className='fw-bold text-danger mb-4 p-4' style={{ backgroundColor: "#F5F5F5" }}>Settings</h3>
//                     <div className={selectedTab === "Primary Details" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Primary Details")}>Primary Details</div>
//                     <div className={selectedTab === "Contact Details" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Contact Details")}>Contact Details</div>
//                     <div className={selectedTab === "Login ID & Password" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Login ID & Password")}>Login ID & Password</div>
//                     <div className={selectedTab === "2FA" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("2FA")}>2 Factor Authentication (2FA)</div>
//                     <hr className='mx-4' />
//                     <div className='p-4'>
//                         <div className='fw-bold mb-4'>{Icons.gear('fs-11')}<span className='ms-3'>for technical support</span></div>
//                         <div className='fw-bold'>Call Us</div>
//                         <div className='text-muted mb-4'>+91- 9284203442</div>
//                         <div className='fw-bold'>Email Us</div>
//                         <div className='text-muted mb-4'>Vidhya@sgrs.org</div>
//                     </div>
//                 </div>

//                 {selectedTab === "Primary Details" &&
//                     <div className='p-5 rounded-5 shadow mt-5 bg-white w-75'>
//                         <div className='d-flex align-items-center mb-3'>
//                             <img src={userImg} alt="" className='mx-3' width={50} />
//                             <div className='mt-3 mb-4 me-5'>
//                                 <p className='fw-bold'>Janmangal Swami</p>
//                                 <p className='fs-11'>Post</p>
//                             </div>
//                         </div>

//                         <Input name={"Name"} containerClass={containerClass} required />
//                         <Input name={"Branch"} containerClass={containerClass} required />
//                         <Input name={"Post"} containerClass={containerClass} required />
//                         <Input name={"S-UID"} containerClass={containerClass} required />

//                         <p className='fs-11'>To update your primary details, kindly get in touch with the SGRS Utility Service Provider
//                             or reach out to our dedicated technical support team for assistance.</p>
//                     </div>
//                 }

//                 {selectedTab === "Contact Details" &&
//                     <div className='p-5 rounded-5 shadow mt-5 bg-white w-75'>
//                         <div className='d-flex align-items-center mb-3'>
//                             <img src={userImg} alt="" className='mx-3' width={50} />
//                             <div className='mt-3 mb-4 me-5'>
//                                 <p className='fw-bold'>Janmangal Swami</p>
//                                 <p className='fs-11'>Post</p>
//                             </div>
//                         </div>
//                         <Input name={"Email"} containerClass={containerClass} required />
//                         <p className='fs-11 mb-5'>(All crucial communications will be conveyed through this designated contact number.)</p>
//                         <Input name={"Contact Number"} containerClass={containerClass} required />
//                         <p className='fs-11 mb-5'>(This email will serve as the primary means for communicating important details
//                             and updates to enhance your overall experience.)</p>

//                         <div className="btn btn-sm rounded-pill text-white px-5 bg-primary">Save Settings</div>
//                     </div>
//                 }

//                 {selectedTab === "Login ID & Password" &&
//                     <div className='p-5 rounded-5 shadow mt-5 bg-white w-75'>
//                         <div className='d-flex align-items-center mb-3'>
//                             <img src={userImg} alt="" className='mx-3' width={50} />
//                             <div className='mt-3 mb-4 me-5'>
//                                 <p className='fw-bold'>Janmangal Swami</p>
//                                 <p className='fs-11'>Post</p>
//                             </div>
//                         </div>
//                         <form className="" onSubmit={changePassOnSubmit} noValidate>
//                             <Input name={"User Id"} containerClass={containerClass} required />
//                             <p className='fs-11 mb-5'>If you suspect that your user ID is at risk of unauthorized access,
//                                 please promptly contact the SGRS Utility Service Administrator to update your user ID.
//                                 Failure to uphold account security measures may result in temporary or permanent suspension from all Utility services.
//                                 Please refer to the terms and conditions for further information.</p>
//                             <p className='fs-11 mb-3'>To update your ID and password, enter your current password,
//                                 input your new password, undergo authentication via 2FA,
//                                 and then proceed to change your password.</p>
//                             <Input name={"Old Password"} containerClass={containerClass} required />
//                             <Input name={"New Password"} containerClass={containerClass} required />
//                             {/* <Input name={"2FA Code (Email)"} containerClass={containerClass} required /> */}
//                             <button className="btn btn-sm rounded-pill text-white px-5 bg-primary">Save Settings</button>
//                         </form>
//                     </div>
//                 }

//                 {selectedTab === "2FA" &&
//                     <div className='p-5 rounded-5 shadow mt-5 bg-white w-75'>
//                         <div className='d-flex align-items-center mb-3'>
//                             <img src={userImg} alt="" className='mx-3' width={50} />
//                             <div className='mt-3 mb-4 me-5'>
//                                 <p className='fw-bold'>Janmangal Swami</p>
//                                 <p className='fs-11'>Post</p>
//                             </div>
//                         </div>
//                         <Input name={"Email"} containerClass={containerClass} required />
//                         <p className='fs-11 mb-5'>(This email will serve as your secondary factor for authentication.)</p>
//                         <Input name={"Contact Number"} containerClass={containerClass} required />
//                         <p className='fs-11 mb-3'>(This contact number will be employed as your secondary factor for authentication.
//                             Please note that 2FA via contact number is currently unavailable.)</p>
//                         <div className="btn btn-sm rounded-pill text-white px-5 bg-primary">Save Settings</div>
//                     </div>
//                 }

//             </div>

//         </div>
//     )
// }

// export default Settings



import React, { useState } from 'react'
import Input from '../../components/form/Input'
import userImg from '../../assets/images/userPhoto.png'
import Icons from '../../components/ui/Icons'
import { useChangePasswordMutation } from '../../services/authSlice'
import SuccessModal from '../../components/modals/SuccessModal'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import { useCreateTechSupportMutation, useGetTechSupportQuery, useGetTermsQuery, useGetUserDetailsQuery, useUpdateEmailOrContactMutation, useUpdateTechSupportMutation } from '../../services/adminSlice'
import Loading from '../../components/ui/Loading'
import Api from '../../constants/Api'
import ViewEditTermsModal from '../../components/modals/ViewEditTermsModal'

const Settings = () => {
    const containerClass = "col mb-4"
    const [selectedTab, setSelectedTab] = useState("Primary Details")
    const activeTabClassName = "border-start border-5 border-primary p-3 fw-bold";
    const inactiveTabClassName = "p-3 fw-bold";

    const response = useGetUserDetailsQuery()

    const [changePassword, changePasswordRes] = useChangePasswordMutation()

    const [createTech, createTechRes] = useCreateTechSupportMutation()
    const [updateTech, updateTechRes] = useUpdateTechSupportMutation()

    const [changeEmail, changeEmailRes] = useUpdateEmailOrContactMutation()

    const getTerms = useGetTermsQuery()

    const getTech = useGetTechSupportQuery()

    const primaryDetailOnSubmit = (e) => {
        e.preventDefault();
        const form = e.target;

        if (form.checkValidity()) {
            const body = {
                name: form['Name'].value,
                branch: form['Branch'].value,
                post: form['Post'].value,
                sUID: form['S-UID'].value,
            }
        }
        else form.classList.add('was-validated')
    }

    const contactDetailOnSubmit = (e) => {
        e.preventDefault();
        const form = e.target;

        if (form.checkValidity()) {
            const body = {
                oldEmail: form['Old Email'].value,
                oldContact: form['Old Contact'].value,
                newEmail: form['New Email'].value,
                newContact: form['New Contact'].value,
                password: form['Password'].value
            }

            changeEmail(body)
                .then(() => {
                    form.reset();
                    response.refetch()

                })
        }
        else form.classList.add('was-validated')
    }

    const OnSubmit2FA = (e) => {
        e.preventDefault();
        const form = e.target;

        if (form.checkValidity()) {
            const body = {
            }
        }
        else form.classList.add('was-validated')
    }

    const changePassOnSubmit = (e) => {
        e.preventDefault()

        const form = e.target;

        if (form.checkValidity()) {



            const body = {
                userName: form['User Name'].value,
                oldPassword: form['Old Password'].value,
                newPassword: form['New Password'].value,
            }

            changePassword(body)
                .then((res) => {
                    if (!res.error) {
                        form.reset()
                        form.classList.remove('was-validated')
                    }
                })

        }
        else form.classList.add('was-validated')

    }


    const handleTechSubmit = (e) => {
        e.preventDefault()
        const body = {
            phone: e.target['Phone'].value,
            email: e.target['Email'].value
        }
        createTech(body)
            .then(() => {

                response.refetch()

            })
    }

    const handleTechSubmitUpdate = (e) => {
        e.preventDefault()
        let id = e.target['Id'].value;
        const body = {
            phone: e.target['Phone'].value,
            email: e.target['Email'].value
        }
        updateTech({ id, body })
            .then(() => {

                response.refetch()

            })
    }

    if (response.isLoading || getTerms.isLoading || getTech.isLoading) return <Loading />
    if (response.isError) return <ApiErrorModal response={response} />
    if (getTerms.isError) return <ApiErrorModal response={getTerms} />
    if (getTech.isError) return <ApiErrorModal response={getTech} />

    return (
        <div className='py-5 d-flex h-100 justify-content-between align-items-center'>

            {
                console.log("eeeeeeeee", getTech.data)
            }

            {changeEmailRes.isSuccess && <SuccessModal label={"Email & Contact Changed!"} message={"Email & Contact  has been changed."} closeTo={"/settings"} />}
            {changeEmailRes.isError && <ApiErrorModal response={changeEmailRes} />}

            {createTechRes.isSuccess && <SuccessModal label={"Email & Contact Changed!"} message={"Information  has been changed."} closeTo={"/settings"} />}
            {createTechRes.isError && <ApiErrorModal response={createTechRes} />}

            {updateTechRes.isSuccess && <SuccessModal label={"Email & Contact Changed!"} message={"Information  has been changed."} closeTo={"/settings"} />}
            {updateTechRes.isError && <ApiErrorModal response={updateTechRes} />}

            {changePasswordRes.isSuccess && <SuccessModal label={"Password Changed!"} message={"Password has been changed."} closeTo={"/settings"} />}
            {changePasswordRes.isError && <ApiErrorModal response={changePasswordRes} />}

            <div className='my-5 mx-2  d-flex h-100 align-items-center'>

                <div className='bg-white rounded-start-4 rounded-end-5 shadow overflow-hidden' style={{ zIndex: 50, width: 300 }}>
                    <h3 className='fw-bold text-danger mb-4 p-4' style={{ backgroundColor: "#F5F5F5" }}>Settings</h3>
                    <div className={selectedTab === "Primary Details" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Primary Details")}>Primary Details</div>
                    <div className={selectedTab === "Contact Details" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Contact Details")}>Contact Details</div>
                    <div className={selectedTab === "Login ID & Password" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Login ID & Password")}>Login ID & Password</div>
                    <div className={selectedTab === "2FA" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("2FA")}>2 Factor Authentication (2FA)</div>
                    <hr className='mx-4' />
                    <div className='p-4'>
                        <div className='d-flex align-items-center mb-4'>
                            <div className='fw-bold'>{Icons.gear('fs-2')}</div>
                            <div className='ms-3'>for technical support</div>
                        </div>

                        <div className='d-flex flex-column gap-2'>
                            {
                                getTech.data.length > 0 ?
                                    <form onSubmit={handleTechSubmitUpdate}>
                                        <Input type={'number'} name={'Phone'} labelName={'Call Us'} defaultValue={getTech?.data[0]?.phone} />
                                        <Input type={'text'} name={'Email'} labelName={'Email Us'} defaultValue={getTech?.data[0]?.email} />
                                        <Input type={'hidden'} name={'Id'} defaultValue={getTech?.data[0]?.id} />
                                        <button type='submit' className='btn btn-primary'>Update</button>
                                    </form>
                                    :
                                    <form onSubmit={handleTechSubmit}>
                                        <Input type={'number'} name={'Phone'} labelName={'Call Us'} defaultValue={getTech?.data[0]?.phone} />
                                        <Input type={'text'} name={'Email'} labelName={'Email Us'} defaultValue={getTech?.data[0]?.email} />

                                        <button type='submit' className='btn btn-primary'>Create</button>
                                    </form>
                            }

                        </div>
                    </div>
                </div>

                {selectedTab === "Primary Details" &&
                    <div className='p-5 rounded-end-5 shadow mt-5 bg-white w-75 tab-content' style={{ height: '80%' }}>
                        <div className='d-flex align-items-center mb-3'>
                            <img src={Api.SUPERADMIN_URL + `uploads/images/profilePictures/${response.data.profilePicture.id}${response.data.profilePicture.ext}`} alt="" className='mx-3' width={50} />
                            <div className='mt-3 mb-4 me-5'>
                                <p className='fw-bold'>{response.data.name}</p>
                                <p className='fs-11'>{response.data.post}</p>
                            </div>
                        </div>

                        <form onSubmit={primaryDetailOnSubmit} noValidate>

                            <Input name={"Name"} labelName={"Name"} containerClass={containerClass} value={response.data.name} disabled required />
                            <Input name={"Branch"} labelName={"Branch"} containerClass={containerClass} value={response?.data.branch?.branchName} disabled required />
                            <Input name={"Post"} labelName={"Post"} containerClass={containerClass} value={response.data.post} disabled required />
                            <Input name={"S-UID"} labelName={"S-UID"} containerClass={containerClass} value={response.data.sUid} disabled required />

                            {/* <button className="btn btn-sm rounded-pill text-white px-5 mb-2  bg-primary">Save Settings</button> */}

                        </form>
                        <p className='fs-11'>To update your primary details, kindly get in touch with the SGRS Utility Service Provider
                            or reach out to our dedicated technical support team for assistance.</p>
                    </div>
                }

                {selectedTab === "Contact Details" &&
                    <div className='p-5 rounded-end-5 shadow mt-5 bg-white w-75 tab-content' style={{ height: '80%' }}>
                        <div className='d-flex align-items-center mb-3'>
                            <img src={Api.SUPERADMIN_URL + `uploads/images/profilePictures/${response.data.profilePicture.id}${response.data.profilePicture.ext}`} alt="" className='mx-3' width={50} />
                            <div className='mt-3 mb-4 me-5'>
                                <p className='fw-bold'>{response.data.name}</p>
                                <p className='fs-11'>{response.data.post}</p>
                            </div>
                        </div>
                        <form onSubmit={contactDetailOnSubmit} noValidate>


                            <Input name={"Old Email"} defaultValue={response.data.email} containerClass={containerClass} required />
                            <Input name={"Old Contact"} defaultValue={response.data.phoneNumber} type={"tel"} maxLength={10} minLength={10} containerClass={containerClass} required />
                            <Input name={"New Email"} containerClass={containerClass} />
                            <Input name={"New Contact"} type={"tel"} maxLength={10} minLength={10} containerClass={containerClass} />
                            {/* <p className='fs-11 mb-5'>(All crucial communications will be conveyed through this designated contact number.)</p> */}
                            <Input name={"Password"} containerClass={containerClass} required />

                            <p className='fs-11 mb-5'>(This email will serve as the primary means for communicating important details
                                and updates to enhance your overall experience.)</p>
                            <button className="btn btn-sm rounded-pill text-white px-5 bg-primary" type='submit'>Save Settings</button>

                        </form>

                    </div>
                }

                {selectedTab === "Login ID & Password" &&
                    <div className='p-5 rounded-end-5 shadow mt-5 bg-white w-75 tab-content' style={{ height: '80%' }}>
                        <div className='d-flex align-items-center mb-3'>
                            <img src={Api.SUPERADMIN_URL + `uploads/images/profilePictures/${response.data.profilePicture.id}${response.data.profilePicture.ext}`} alt="" className='mx-3' width={50} />
                            <div className='mt-3 mb-4 me-5'>
                                <p className='fw-bold'>{response.data.name}</p>
                                <p className='fs-11'>{response.data.post}</p>
                            </div>
                        </div>
                        <form className="" onSubmit={changePassOnSubmit} noValidate>
                            <Input name={"User Name"} containerClass={containerClass} required />
                            <p className='fs-11 mb-5'>If you suspect that your user ID is at risk of unauthorized access,
                                please promptly contact the SGRS Utility Service Administrator to update your user ID.
                                Failure to uphold account security measures may result in temporary or permanent suspension from all Utility services.
                                Please refer to the terms and conditions for further information.</p>
                            <p className='fs-11 mb-3'>To update your ID and password, enter your current password,
                                input your new password, undergo authentication via 2FA,
                                and then proceed to change your password.</p>
                            <Input name={"Old Password"} containerClass={containerClass} required />
                            <Input name={"New Password"} containerClass={containerClass} required />
                            {/* <Input name={"2FA Code (Email)"} containerClass={containerClass} required /> */}
                            <button className="btn btn-sm rounded-pill text-white px-5 bg-primary">Save Settings</button>
                        </form>
                    </div>
                }

                {selectedTab === "2FA" &&
                    <div className='p-5 rounded-end-5 shadow mt-5 bg-white w-75 tab-content' style={{ height: '80%' }}>
                        <div className='d-flex align-items-center mb-3'>
                            <img src={userImg} alt="" className='mx-3' width={50} />
                            <div className='mt-3 mb-4 me-5'>
                                <p className='fw-bold'>Janmangal Swami</p>
                                <p className='fs-11'>Post</p>
                            </div>
                        </div>
                        <form onSubmit={OnSubmit2FA} noValidate>
                            <Input name={"Email"} containerClass={containerClass} required />
                            <p className='fs-11 mb-5'>(This email will serve as your secondary factor for authentication.)</p>
                            <Input name={"Contact Number"} type={"tel"} maxLength={10} minLength={10} containerClass={containerClass} required />
                            <p className='fs-11 mb-3'>(This contact number will be employed as your secondary factor for authentication.
                                Please note that 2FA via contact number is currently unavailable.)</p>
                            <button className="btn btn-sm rounded-pill text-white px-5 bg-primary">Save Settings</button>
                        </form>
                    </div>
                }

            </div>

            <div className='py-5 h-100'>
                <div className='d-flex flex-column h-100 overflow-hidden rounded-start-5 shadow' style={{ width: 300 }}>
                    <div className='bg-info  p-3 d-flex justify-content-between px-3 align-items-center '>
                        <div className='fw-bold text-danger'>Terms and Conditions</div>
                        {/* <div className='rounded-4 bg-primary btn btn-sm text-white px-3 py-1'>Edit</div> */}
                        {
                            getTerms.data.length > 0 ?
                                <ViewEditTermsModal isCreate={false} id={getTerms.data[0].id} refetch={getTerms.refetch} />
                                :
                                <ViewEditTermsModal isCreate={true} id={1} refetch={getTerms.refetch} />
                        }
                    </div>
                    <div className='flex-grow-1 bg-white pt-4 px-3' dangerouslySetInnerHTML={{ __html: getTerms?.data[0]?.content }}>



                    </div>
                    <div className='text-center bg-info  fw-bold p-3'>Accepted <span className='text-dark fs-11'>{getTerms?.data[0]?.updatedAt}</span></div>
                </div>
            </div>

        </div>
    )

}

export default Settings
