import React, { useEffect, useState } from "react";
import poster from "../assets/images/icgs_poster.png";
import { Link } from "react-router-dom";
import userImg from "../assets/images/userPhoto.png";
import userImg2 from "../assets/images/user2.png";
import { useGetNoticesQuery } from "../services/noticeSlice";
import Loading from "../components/ui/Loading";
import ApiErrorModal from "../components/modals/ApiErrorModal";
import ViewNoticeModal from "../components/modals/ViewNoticeModal";
import {
  useCreateSliderMutation,
  useGetSliderQuery,
} from "../services/sliderSlice";
import Cropper from "../components/Cropper";
import Api from "../constants/Api";
import SuccessModal from "../components/modals/SuccessModal";
import Slider from "../components/Slider";
import dashboardImg from "../assets/images/dashboardimg.png";
import CircularProgressBar from "../components/ui/CircularProgressBar";
import CircularProgressBarBig from "../components/ui/CircularProgressBarBig";
import {
  useGetCircularsQuery,
  useGetMarkReadCircularQuery,
} from "../services/circularSlice";
import { useGetPoliciesQuery } from "../services/policySlice";
import ViewEditInfoModal from "../components/modals/ViewEditInfoModal";
import ViewEditDashDataModal from "../components/modals/ViewEditDashDataModal";
import { useGetTechSupportQuery } from "../services/adminSlice";

const Dashboard = () => {
  const noticeRes = useGetNoticesQuery();
  const sliderRes = useGetSliderQuery();

  const markAsReadEntries = useGetMarkReadCircularQuery();
  const circularEntries = useGetCircularsQuery();
  const policyEntries = useGetPoliciesQuery();

  const getTechData = useGetTechSupportQuery();

  const [progress, setProgress] = useState({
    total: 0,
    school: 0,
    hostel: 0,
    circular: 0,
    education: 0,
    kanyaGurukul: 0,
  });

  useEffect(() => {
    // Calculate progress for each category
    const total = circularEntries?.data?.length + policyEntries?.data?.length;

    // const school = circularEntries?.data?.markAsReads?.filter(entry => ((entry?.policyId && entry?.policy.type == 'SCHOOL') )).length;

    const school = markAsReadEntries?.data?.filter(
      (entry) => entry?.policyId && entry?.policy.type == "SCHOOL"
    ).length;

    const hostel = markAsReadEntries?.data?.filter(
      (entry) => entry?.policyId && entry?.policy.type == "HOSTEL"
    ).length;
    const circular = markAsReadEntries?.data?.filter(
      (entry) => entry.circularId
    ).length;
    const education = markAsReadEntries?.data?.filter(
      (entry) => entry?.policyId && entry?.policy.type == "EDUCATION"
    ).length;
    const kanyaGurukul = markAsReadEntries?.data?.filter(
      (entry) => entry?.policyId && entry?.policy.type == "KANYAGURUKUL"
    ).length;

    // Update state with the calculated progress
    setProgress({ total, school, hostel, circular, education, kanyaGurukul });
  }, [
    markAsReadEntries.isSuccess,
    circularEntries.isSuccess,
    policyEntries.isSuccess,
  ]);

  if (noticeRes.isLoading || sliderRes.isLoading || getTechData.isLoading)
    return <Loading />;
  if (noticeRes.isError) return <ApiErrorModal response={noticeRes} />;
  if (sliderRes.isError) return <ApiErrorModal response={sliderRes} />;

  return (
    <div className="container-fluid px-4 py-3 h-100">
      <div className="row h-100 p-3 overflow-hidden hide-scroll">
        <div className="col-8 d-flex flex-column overflow-auto h-100">
          <Slider type="Dashboard" />

          <div className="bg-white p-4 rounded-5 flex-grow-1 shadow">
            <div className="d-flex align-items-center  py-2 border-bottom mb-3">
              <img
                className="me-3 "
                src={dashboardImg}
                style={{ width: 65 }}
                alt=""
              />
              <div className="fs-5">
                Welcome to the Shikshan Pravrutti Portal. <br />
                This IMS portal is managed by SGRS CO vidhya vibhag.
              </div>
            </div>

            <div className="d-flex  bg-white rounded-4  ">
              <div className=" bg-white w-100 ">
                <h4 className="fw-bold fs-1 mb-3">Support</h4>
                <div className=" d-flex justify-content-between">
                  <div className="">
                    <p className="text-muted">We are available between</p>
                    <p className="fw-bold mb-3">{getTechData.data[0].title}</p>
                  </div>
                  <div className="">
                    <p className="fw-bold">Address</p>
                    <p className="text-muted mb-3 text-wrap">
                      {getTechData.data[0].address}
                    </p>
                  </div>
                </div>

                <div className="d-flex justify-content-between w-75">
                  <div>
                    <p className="fw-bold">WhatsApp Us</p>
                    <p className="text-muted mb-3">
                      {getTechData.data[0].whatsapp}
                    </p>
                  </div>
                  <div>
                    <p className="fw-bold">Call Us</p>
                    <p className="text-muted mb-3">
                      {getTechData.data[0].phone}
                    </p>
                  </div>
                  <div>
                    {" "}
                    <p className="fw-bold">Email Us</p>
                    <p className="text-muted mb-3">
                      {getTechData.data[0].email}
                    </p>
                  </div>
                </div>
                {/* <div className="container p-0 bg-danger ">
                  <div className="row row-cols-2  ">
                    <div className="col">
                      <p className="fw-bold">WhatsApp Us</p>
                      <p className="text-muted mb-3">
                        {getTechData.data[0].whatsapp}
                      </p>
                    </div>
                    <div className="col">
                      <p className="fw-bold">Call Us</p>
                      <p className="text-muted mb-3">
                        {getTechData.data[0].phone}
                      </p>
                    </div>
                    <div className="col">
                      <p className="fw-bold">Email Us</p>
                      <p className="text-muted mb-3">
                        {getTechData.data[0].email}
                      </p>
                    </div>
                  </div>
                </div> */}

                <ViewEditDashDataModal
                  id={1}
                  downloadableRes={getTechData}
                  refetch={getTechData.refetch}
                />
              </div>
              {/* <div className='w-50 text-center' >
    <img src={progressImg} alt="" style={{ width: 300 }} />
  </div> */}
            </div>

          </div>
        </div>

        <div className="col-4 h-100">
          <div className="card px-5 rounded-5  h-100 d-flex flex-column hide-scroll shadow">
            <div className="text-center overflow-hidden mb-3  pb-5">
              <span className="text-white shadow text-center bg-primary rounded-bottom-4 px-5 py-2 mb-3 fs-4">
                Notices
              </span>
            </div>

            <div className="d-flex flex-column overflow-hidden flex-grow-1 ">
              <div className="overflow-auto flex-grow-1 align-items-center justify-content-center">
                {noticeRes.data.map((item) => (
                  <div key={item.id}>
                    <div className="d-flex align-items-center">
                      <div className="bg-secondary py-2 px-3 rounded-4 text-white justify-content-center me-3">
                        <h3>21</h3>
                        <p className="fs-13">Jun</p>
                      </div>
                      <p className="fw-bold">
                        {item.title} <ViewNoticeModal id={item.id} /> |{" "}
                        <Link to={"/notice/" + item.id} className="text-danger">
                          edit
                        </Link>
                      </p>
                    </div>
                    <hr className="text-light" />
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-end my-3">
                <Link
                  to={"notice/create"}
                  className="btn btn-primary rounded-pill px-5 ms-4 mb-3"
                >
                  Create
                </Link>
                <Link
                  className="btn btn-primary rounded-pill px-5 ms-4 mb-3"
                  to={"notice"}
                >
                  History
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className=" h-100  p-5">
      <div className="row h-100 ">
        <div className="col-8 ">
          <Slider type="Dashboard" />

          {/* //======================= */}

          <div className="bg-white p-4 rounded-5">
            <div className="d-flex align-items-center  py-2 border-bottom mb-3">
              <img
                className="me-3 "
                src={dashboardImg}
                style={{ width: 80 }}
                alt=""
              />
              <div className="fs-">
                Welcome to the Shikshan Pravrutti Portal. <br />
                This IMS portal is managed by SGRS CO vidhya vibhag.
              </div>
            </div>
            <div className="d-flex  bg-white rounded-4">
              <div className=" bg-white w-100 border-end ">
                <h4 className="fw-bold fs-1 mb-3">Support</h4>
                <p className="text-muted">We are available between</p>
                <p className="fw-bold mb-3">{getTechData.data[0].title}</p>

                <p className="fw-bold">Address</p>
                <p className="text-muted mb-3 ">
                  {getTechData.data[0].address}
                </p>

                <p className="fw-bold">WhatsApp Us</p>
                <p className="text-muted mb-3">
                  {getTechData.data[0].whatsapp}
                </p>

                <p className="fw-bold">Call Us</p>
                <p className="text-muted mb-3">{getTechData.data[0].phone}</p>

                <p className="fw-bold">Email Us</p>
                <p className="text-muted mb-3">{getTechData.data[0].email}</p>

                <ViewEditDashDataModal id={1} downloadableRes={getTechData} />
              </div>
              {/* <div className='w-50 text-center' >
    <img src={progressImg} alt="" style={{ width: 300 }} />
  </div> */}
              <div
                className=" card shadow rounded-3 text-center p-4"
                style={{ width: "100%" }}
              >
                <h5 className="text-primary fw-bold fs-4">
                  {" "}
                  Your Awareness Progress Report
                </h5>

                <div className="d-flex justify-content-around align-items-center">
                  <div className="">
                    <CircularProgressBarBig progress={progress.total} />
                  </div>

                  <div className="mt-3   d-flex flex-column jusify-content-center align-items-center">
                    <div className="d-flex  align-items-center   w-100 ">
                      <CircularProgressBar progress={progress.school} />
                      <p className="text-primary fw-bold">SCHOOL</p>
                    </div>

                    <div className="d-flex align-items-center  w-100 ">
                      <CircularProgressBar progress={progress.hostel} />
                      <p className="text-primary fw-bold">HOSTEL</p>
                    </div>

                    <div className="d-flex  align-items-center  w-100 ">
                      <CircularProgressBar progress={progress.circular} />
                      <p className="text-primary fw-bold">CIRCULAR</p>
                    </div>

                    <div className="d-flex align-items-center  w-100 ">
                      <CircularProgressBar progress={progress.education} />
                      <p className="text-primary fw-bold">EDUCATION</p>
                    </div>

                    <div className="d-flex  align-items-center  w-100">
                      <CircularProgressBar progress={progress.kanyaGurukul} />
                      <p className="text-primary fw-bold"> KANYA</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ================ */}
        </div>

        <div className="col-4 h-100">
          <div className="card px-5 rounded-4 overflow-hidden hide-scroll h-100 d-flex flex-column ">
            <div>
              <div className=" text-white text-center bg-primary rounded-bottom-4 px-3 py-2 mb-3">
                Notices
              </div>
            </div>

            <div className="overflow-auto ">
              {noticeRes.data.map((item) => (
                <div key={item.id}>
                  <div className="d-flex align-items-center">
                    <div className="bg-secondary py-2 px-3 rounded-4 text-white justify-content-center me-3">
                      <h3>21</h3>
                      <p className="fs-13">Jun</p>
                    </div>
                    <p className="fw-bold">
                      {item.title} <ViewNoticeModal id={item.id} /> |{" "}
                      <Link to={"/notice/" + item.id} className="text-danger">
                        edit
                      </Link>
                    </p>
                  </div>
                  <hr className="text-light" />
                </div>
              ))}

              <div className="my-3 d-flex justify-content-end">
                <Link
                  to={"notice/create"}
                  className="btn btn-primary rounded-pill px-5 ms-4 mb-3"
                >
                  Create
                </Link>
                <Link
                  className="btn btn-primary rounded-pill px-5 ms-4 mb-3"
                  to={"notice"}
                >
                  History
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  //Brahmavidhya admin Dashboard
  return (
    <div className="container p-5">
      <div className="row">
        <div className="col-7">
          <Slider type="Dashboard" />

          <div className="d-flex">
            <div className="card border-0 p-3 rounded-4">
              <div className="d-flex mb-3">
                <img src={userImg} width={75} alt="" />
                <div className="ms-3">
                  <h4 className="fw-bold">Janmangal Swami</h4>
                  <p>Administrator Account</p>
                  <p className="text-muted">User Id:</p>
                </div>
              </div>

              <p>Email: </p>
              <p>Contact No: </p>

              <p className="text-muted text-end">Change Password ?</p>
            </div>

            <div className="p-3 bg-warning rounded-4 flex-grow-1 ms-3">
              <h4 className="fw-bold mb-3">LEADER BOARD</h4>
              <div className="d-flex bg-white p-2 rounded-3 align-items-center mb-3">
                <img src={userImg2} alt="" />
                <div className="mx-3">
                  <h6 className="fw-bold">Hari Kumar</h6>
                  <p>2334 credits</p>
                </div>
              </div>

              <div className="d-flex bg-white p-2 rounded-3 align-items-center mb-3">
                <img src={userImg2} alt="" />
                <div className="mx-3">
                  <h6 className="fw-bold">Hari Kumar</h6>
                  <p>2334 credits</p>
                </div>
              </div>

              <div className="d-flex bg-white p-2 rounded-3 align-items-center">
                <img src={userImg2} alt="" />
                <div className="mx-3">
                  <h6 className="fw-bold">Hari Kumar</h6>
                  <p>2334 credits</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-5">
          <div className="card px-5 rounded-4">
            <div className="btn btn-primary rounded-bottom-4 rounded-top-0 px-3 mb-3">
              Notices
            </div>

            {noticeRes.data.map((item) => (
              <div>
                <div className="d-flex align-items-center">
                  <div className="bg-secondary py-2 px-3 rounded-4 text-white justify-content-center me-3">
                    <h3>21</h3>
                    <p className="fs-13">Jun</p>
                  </div>
                  <p className="fw-bold">
                    {item.title} <ViewNoticeModal id={item.id} /> |{" "}
                    <Link to={"/notice/" + item.id} className="text-danger">
                      edit
                    </Link>
                  </p>
                </div>
                <hr className="text-light" />
              </div>
            ))}

            <div className="my-3 d-flex justify-content-end">
              <Link
                to={"notice/create"}
                className="btn btn-primary rounded-pill px-5 ms-4 mb-3"
              >
                Create
              </Link>
              <Link className="btn btn-primary rounded-pill px-5 ms-4 mb-3">
                History
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
