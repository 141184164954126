import React from 'react'
import Input from '../../components/form/Input'
import { useGetArchiveByIdQuery, useUpdateArchiveMutation } from '../../services/archiveSlice'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import { useNavigate, useParams } from 'react-router-dom'
import Loading from '../../components/ui/Loading'
import ImageInput from '../../components/form/ImageInput'
import Button from '../../components/form/Button'
import Api from '../../constants/Api'
import FormattedInput from '../../components/form/FormattedInput'


const EditArchive = () => {

    const { id } = useParams()
    const navigate = useNavigate()
    const [updateArchive, updateArchiveRes] = useUpdateArchiveMutation()
    const response = useGetArchiveByIdQuery(id)

    const onSubmit = (event) => {
        event.preventDefault();

        const form = event.target;
        if (form.checkValidity()) {
            const files = form['images'].files
            let body = new FormData();
            body.append("title", form['Title'].value);
            body.append("content", form['Content'].value);
            for (let index = 0; index < files.length; index++) body.append("images", files[index])
            updateArchive({ id, body }).then((res) => {
                if (!res.error) {
                    navigate(-1)
                }
            })
        }


        form.classList.add('was-validated')
    }


    if (response.isLoading) return <Loading />
    if (response.isError) return <ApiErrorModal response={response} />

    return (
        <div className='p-5'>

            {updateArchiveRes.isError && <ApiErrorModal response={updateArchiveRes} />}

            <h2 className='fw-bold text-danger'>Educational  Archives</h2>
            <p className='mb-5'>This all are the latest updates on our school</p>

            <form className="" onSubmit={onSubmit} noValidate>
                <div className='card p-3 flex-row'>
                    <div className='flex-grow-1'>
                        <Input name={'Title'} containerClass={'mb-3'} defaultValue={response.data.title} required />
                        <FormattedInput name={"Content"} defaultValue={response.data.content} />
                        {/* <div>
                            <button type='submit' className="btn btn-primary rounded-pill px-5 text-white mt-3">Update</button>
                        </div> */}
                        <div>
                            <Button
                                className="btn btn-primary rounded-pill px-5 text-white mt-3"
                                res={updateArchiveRes}
                                loadingLabel={"Updating"}
                            >
                                Update
                            </Button>

                        </div>
                    </div>
                    <div className="card p-5 rounded-5 text-center ms-3">
                        {/* <label htmlFor="images" className='p-5 rounded-3 text-primary' style={{ borderStyle: 'dashed', borderColor: '#D0DBF8' }}>Browse file to upload</label> */}
                        <div className='m-3' style={{ width: 250 }}>
                            <ImageInput
                                name={"images"}
                                labelName={'Browse file to upload'}
                                labelClass={'p-5 rounded-3 text-primary'}
                                labelStyle={{ borderStyle: 'dashed', borderColor: '#D0DBF8' }}
                                defaultValue={response.data.images.map(i => (Api.IMAGE_URL + i.id + i.ext))}
                                multiple
                            >
                                <div className='invalid-feedback'>*Select Image</div>
                            </ImageInput>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    )
}

export default EditArchive