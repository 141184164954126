import { createSelector } from "@reduxjs/toolkit";
import { superSlice } from "../redux/superSlice";

export const extendedApiSlice = superSlice.injectEndpoints({
    endpoints: builder => ({
        getSupportTickets: builder.query({ query: () => `support`, keepUnusedDataFor: 0 }),
        getSupportTicketById: builder.query({ query: (id) => `support?id=${id}`, keepUnusedDataFor: 0 }),
        deleteSupportTicket: builder.mutation({ query: (id) => ({ url: `support?id=${id}`, method: 'DELETE' }) }),
        sendMessage: builder.mutation({ query: ({ id, body }) => ({ url: `support/chat?id=${id}`, method: 'POST', body }) }),
        refreshToken: builder.mutation({ query: () => ({ url: `refresh-token`, method: 'POST'}) }),
        
    })
})

export const {
    useGetSupportTicketsQuery,
    useGetSupportTicketByIdQuery,
    useDeleteSupportTicketMutation,
    useSendMessageMutation,
    useRefreshTokenMutation
} = extendedApiSlice
