import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Headbar from './Headbar'
import Sidebar from './Sidebar'
import CheckAndRefreshToken from './CheckAndRefreshToken'

const Layout = () => {

    const { pathname } = useLocation()

    useEffect(() => {
        const classList = document.body.classList
        if (classList.contains("modal-open")) classList.remove("modal-open")

        const backdrops = document.getElementsByClassName("modal-backdrop")
        for (const backdrop of backdrops) backdrop.remove()
    }, [pathname])

    // return (

    //     <div className='h-100 w-100 overflow-hidden d-flex'>
    //         <Sidebar />
    //         <div className='d-flex flex-column overflow-hidden flex-grow-1'>
    //             <Headbar />
    //             <main className='flex-grow-1 pagesBack overflow-hidden'>
    //                 <Outlet />
    //             </main>
    //         </div>
    //     </div>
    // )


    return (

        <div className='h-100 w-100 overflow-hidden d-flex'>
            <Sidebar />
            <CheckAndRefreshToken/>
            <div className='d-flex flex-column overflow-hidden flex-grow-1'>
                <Headbar />
                <main className='flex-grow-1 bg-secondary bg-opacity-10 overflow-auto pagesBack'>
                    <div className='h-100' style={{zIndex:'5'}}>
                        <Outlet />
                    </div>

                </main>
            </div>
        </div>
    )

}

export default Layout