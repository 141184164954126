import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Icons from '../ui/Icons'
import Button from '../form/Button'
import { getApiErrorMessage } from '../../utility/errorMessages'
import Input from '../form/Input'
import Loading from '../ui/Loading'
import { useGetCategoryByIdQuery, useUpdateCategoryMutation } from '../../services/categorySlice'

const EditCategoryNameModal = ({ id, refetch, refetch2 }) => {

    const modalId = "editCategoryNameModal" + id
    const navigate = useNavigate()
    // const [name, setName] = useState(null)

    const [updateCategory, updateCategoryRes] = useUpdateCategoryMutation()
    const categoryRes = useGetCategoryByIdQuery(id)

    // const onChange = (e) => {
    //     setName(e.target['Name'].value)
    //     console.log(name);
    // }

    const onUpdate = (e) => {
        e.preventDefault()

        const body = {
            title: e.target['Name'].value
        }

        updateCategory({ id, body})
        .then((res) => {
            if (!res.error) {
                document.getElementById(modalId + 'CloseBtn').click()
                refetch()
                refetch2()
            }
        })
}

if (categoryRes.isLoading) return <Loading />
if (categoryRes.isError) return <div>Something went wrong...</div>

return (
    <>
        <button type="button" className="btn" data-bs-toggle="modal" data-bs-target={"#" + modalId}>
            {Icons.edit()}
        </button>

        <div className="modal fade" id={modalId} tabindex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">
            <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content">
                    <form className="" onSubmit={onUpdate}>
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id={modalId + "Label"}>Edit Category Name</h1>
                            <button id={modalId + "CloseBtn"} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <Input name={'Name'} defaultValue={categoryRes.data.title} />
                        </div>
                        <div className="modal-footer">
                            {updateCategoryRes.isError &&
                                <div className='me-auto fw-bold text-danger'>
                                    {getApiErrorMessage(updateCategoryRes).label}: {getApiErrorMessage(updateCategoryRes).message}
                                </div>
                            }
                            <button type="button" className="btn btn-success text-white" data-bs-dismiss="modal">Cancel</button>
                            <Button
                                className="btn btn-danger text-white"
                                type="submit"
                                res={updateCategoryRes}
                                loadingLabel={"Updating"}
                            >
                                Update
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
)
}

export default EditCategoryNameModal