import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSlider: builder.query({ query: () => `slider`, keepUnusedDataFor: 0 }),
        getSliderById: builder.query({ query: (id) => `slider?id=${id}`, keepUnusedDataFor: 0 }),
        createSlider: builder.mutation({ query: (body) => ({ url: `slider`, method: 'POST', body }) }),
        updateSlider: builder.mutation({ query: ({ id, body }) => ({ url: `slider?id=${id}`, method: 'PATCH', body }) }),
        deleteSlider: builder.mutation({ query: (id) => ({ url: `slider?id=${id}`, method: 'DELETE' }) }),
    })
})

export const {
    useGetSliderQuery,
    useGetSliderByIdQuery,
    useCreateSliderMutation,
    useUpdateSliderMutation,
    useDeleteSliderMutation
} = extendedApiSlice
