import React from 'react'
import { useGetCategoriesQuery } from '../../services/categorySlice';
import { useGetPolicyByIdQuery, useUpdatePolicyMutation } from '../../services/policySlice';
import Loading from '../../components/ui/Loading';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import Input from '../../components/form/Input';
import { useParams } from 'react-router-dom';
import Button from '../../components/form/Button';
import FormattedInput from '../../components/form/FormattedInput';

const EditPolicy = ({ type }) => {

    const { id } = useParams()
    const formattedInputContentName = "formattedInputContentName" + id;
    const formattedInputTestContentName = "formattedInputTestContentName" + id;

    const categoryRes = useGetCategoriesQuery()
    const policyRes = useGetPolicyByIdQuery(id)
    const [updatePolicy, updatePolicyRes] = useUpdatePolicyMutation()

    const onPolicySubmit = (event) => {
        event.preventDefault();
        const form = event.target

        const body = {
            title: form['Policy Title'].value,
            content: form[formattedInputContentName].value,
            pdfLink: form['PDF Link'].value,
            enableDownload: form['downloadSwitch'].checked,
            enableText: form['testSwitch'].checked,
            testContent: form[formattedInputTestContentName].value,
            categoryId: form['Category Select'].value
        }

        updatePolicy({ id, body })
    }

    if (policyRes.isLoading) return <Loading />
    if (policyRes.isError) return <ApiErrorModal response={policyRes} />

    return (
        <div>
            {/* {updatePolicyRes.isSuccess && <SuccessModal label={"Updated!"} message={"Policy has been updated."} to={{ link: "/", text: "Go to Dashboard" }} closeTo={"/downloadables"} />} */}
            {updatePolicyRes.isError && <ApiErrorModal response={updatePolicyRes} />}

            <form className="" onSubmit={onPolicySubmit}>
                <div className='bg-white rounded-4 px-5 pt-5'>

                    <Input name={'Policy Title'} containerClass={'mb-3'} defaultValue={policyRes.data.title} required />

                    <h6 className='fs-13 fw-bold mt-4'>Choose Category</h6>
                    <select class="form-select" aria-label="Category Select" name='Category Select' required>
                        {
                            categoryRes.isSuccess && categoryRes.data.map(item =>
                                <option selected={policyRes.data.category.title === item.title ? true : false} value={item.id}>{item.title}</option>
                            )
                        }
                    </select>

                    <h6 className='fs-13 fw-bold mt-4'>Policy Content</h6>
                    <FormattedInput name={formattedInputContentName} defaultValue={policyRes.data.content} />

                    <Input name={'PDF Link'} containerClass={'mb-3'} defaultValue={policyRes.data.pdfLink} required />

                    <div className="form-check form-switch my-3">
                        <label className="form-check-label" htmlFor="downloadSwitch">Enable download</label>
                        <input className="form-check-input" type="checkbox" defaultChecked={policyRes.data.enableDownload} role="switch" id="downloadSwitch" name='downloadSwitch' />
                    </div>

                    <div className="form-check form-switch my-3">
                        <label className="form-check-label" htmlFor="testSwitch">Enable test</label>
                        <input className="form-check-input" type="checkbox" defaultChecked={policyRes.data.enableText} role="switch" id="testSwitch" name='testSwitch' />
                    </div>

                    <h6 className='fs-13 fw-bold mt-4'>Test Content</h6>
                    <FormattedInput name={formattedInputTestContentName} defaultValue={policyRes.data.testContent} />

                    <Button
                        className="btn btn-primary px-5 rounded-pill my-4"
                        res={updatePolicyRes}
                        loadingLabel={"Updating"}
                    >
                        Update the Policy
                    </Button>

                    {/* <button type='submit' className="btn btn-primary px-5 rounded-pill my-4" style={{ marginTop: -20 }}>Update the Policy</button> */}
                </div>
            </form>
        </div>
    )
}

export default EditPolicy