import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getArchives: builder.query({ query: () => `archive`, keepUnusedDataFor: 0 }),
        getArchiveById: builder.query({ query: (id) => `archive?id=${id}`, keepUnusedDataFor: 0 }),
        createArchive: builder.mutation({ query: (body) => ({ url: `archive`, method: 'POST', body }) }),
        updateArchive: builder.mutation({ query: ({ id, body }) => ({ url: `archive?id=${id}`, method: 'PATCH', body }) }),
        deleteArchive: builder.mutation({ query: (id) => ({ url: `archive?id=${id}`, method: 'DELETE' }) }),
    })
})

export const {
    useGetArchivesQuery,
    useGetArchiveByIdQuery,
    useCreateArchiveMutation,
    useUpdateArchiveMutation,
    useDeleteArchiveMutation
} = extendedApiSlice
