import React from 'react'
import { useGetPolicyByIdQuery } from '../services/policySlice'
import { useParams } from 'react-router-dom'
import { useState } from 'react';
import Loading from '../components/ui/Loading'
import ApiErrorModal from '../components/modals/ApiErrorModal'

const ViewPolicy = () => {

    const { id } = useParams()

    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);

    const response = useGetPolicyByIdQuery(id)

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    if (response.isLoading) return <Loading />
    if (response.isError) return <ApiErrorModal response={response} />

    return (
        <div className='p-5  h-100'>

        <div className='d-flex align-items-start justify-content-between'>
            <div className='mb-3'>
                <h4 className='fw-bold text-danger'>{response.data.title}</h4>
                <div className='text-muted mb-4' dangerouslySetInnerHTML={{ __html: response.data.content }}></div>
            </div>
            <div>

          
            </div>
        </div>


        <div className="  w-100    " style={{height:'75vh'}}>

            {
                response.data.pdfLink.endsWith('.pdf') ?
                    <object data={`${response.data.pdfLink}#toolbar=0&navpanes=0&scrollbar=0`}
                        width="100%"
                        height="100%">
                    </object>
                    :
                    <div>Invalid URL</div>
            }

        </div>

        {/* {response.data.pdfLink.endsWith('.pdf') ? (
<iframe src={`https://docs.google.com/viewer?url=${encodeURIComponent(response.data.pdfLink)}&embedded=true&rm=embed`} title="PDF Viewer" width="100%" height="500" />
) : (
<div>Invalid PDF URL</div>
)} */}


    </div>
    )
}

export default ViewPolicy