import React from 'react'

const Select = ({ options, name, labelClass, containerClass, inputClass, setValue, selectedValue, ...attributes }) => {

    const containerClassName = "d-flex align-items-center " + (containerClass ? containerClass : "")
    const labelClassName = "fw-bold fs-13 text-nowrap  " + (labelClass ? labelClass : "")
    const inputSelectClassName = "form-select form-select-md my-3 rounded-pill  bg-light shadow-sm " + (inputClass ? inputClass : "")

    const onChange = (e) => setValue && setValue(e.target.value)

    return (
        <div className={containerClassName}>
            <label htmlFor={name} className={labelClassName}>{name}</label>
            <select
                className={inputSelectClassName}
                name={name}
                id={name}
                onChange={onChange}
                {...attributes}
            >
                {Object.values(options).map((option) => <option value={option} selected={option === selectedValue ? true : false}>{option}</option>)}
            </select>
        </div>

    )
}

export default Select
