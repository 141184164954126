import React, { useEffect, useState } from 'react'
import Icons from '../../components/ui/Icons'
import { Link, useNavigate } from 'react-router-dom'
import { useGetSupportTicketByIdQuery, useGetSupportTicketsQuery } from '../../services/supportSlice';
import Loading from '../../components/ui/Loading';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import SupportAction from './SupportAction';
import ticketImg from '../../assets/images/ticket.png'
import rodImg from '../../assets/images/rod.png'
import supportLogo from '../../assets/images/supportLogo.png'
import { transformDateOnly } from '../../utility/date';

const Support = () => {

  const supportTicketsRes = useGetSupportTicketsQuery();
  const navigate = useNavigate();

  const [selectedTicket, setSelectedTicket] = useState(null);

  const handleToggle = (item) => {
    setSelectedTicket(item.id)

  }

  if (supportTicketsRes.isLoading) return <Loading />
  if (supportTicketsRes.isError) return <ApiErrorModal response={supportTicketsRes} />

  return (

    <div className='d-flex hide-scroll bg-dange p-4 h-100'>

      <div className=' flex-grow-1 mx-lg-3 overflow-hidden bg-dange pb-3'>

        <div className="d-flex align-items-center justify-content-between rounded-4 bg-primary p-3 border-bottom">
          <h2 className='text-white fw-bold'>Tickets</h2>

        </div>

        <div className='pb-5 overflow-y-auto h-100 '>

          {/* {supportTicketsRes.data?.map((item) =>
            <div className='d-flex bg-white rounded-4 m-3 shadow ' key={item.id}>

              <div className='p-4 border-end w-25 '>
                <h6 className='fw-bold'>Ticket ID</h6>
                <p className='fs-13 text-muted'>{item.id}</p>
              </div>

              <div className='p-4  border-end w-25'>
                <h6 className='fw-bold'>Topic</h6>
                <p className='fs-13 text-muted'>{item.topic.length > 15 ? item.topic.substring(0, 14) + "..." : item.topic}</p>
              </div>

              <div className='p-4 pe-5 border-end flex-grow-1'>
                <h6 className='fw-bold'>Last Updated</h6>
                <p className='fs-13 text-muted'>{item.updatedAt}</p>
              </div>

              <div className='p-4 d-flex align-items-center w-25'>
                {
                  (item.status == 'Created') ?
                    <div className="btn btn-sm px-3 rounded-pill text-white" style={{ backgroundColor: "red" }}>Unresolve</div>
                    :
                    <div className="btn btn-sm px-3 rounded-pill text-white" style={{ backgroundColor: "#508D02" }}>Resolved</div>

                }
              </div>
              <div className='d-flex p-4'>
                <div className='btn p-0' onClick={() => setSelectedTicket(item.id)}>{Icons.arrow()}</div>
              </div>

            </div>
          )} */}

          {supportTicketsRes.data
            .map((item) =>
              <div className="" key={item.id}>
                <div className='bg-white rounded-4 my-3 shadow container m-0 ' key={item.id} onClick={() => handleToggle(item)}>

                  <div className="row flex-nowrap overflow-x-auto">
                    <div className="col">
                      <div className='p-3 border-end  '>
                        <h6 className='fw-bold'>ID</h6>
                        <p className='fs-13 text-muted'>{item.id}</p>
                        {/* <p className='fs-13 text-muted'>{new Date(item.createdAt).toDateString()}</p> */}
                      </div>
                    </div>
                    <div className="col-5">
                      <div className='py-3 border-end '>
                        <h6 className='fw-bold'>
                          <span className={"d-lg-none " + (item.status === 'Created' ? 'text-danger' : 'text-success')}>&#9679;</span>Topic
                        </h6>
                        {/* <p className='fs-13 text-muted'>{item.topic.length > 15 ? item.topic.substring(0, 14) + "..." : item.topic}</p> */}
                        <p className='fs-13 text-muted overflow-x-hidden text-nowrap me-2'>{item.topic}</p>
                      </div>
                    </div>
                    <div className="col">
                      <div className='py-3 border-lg-end '>
                        <h6 className='fw-bold'>Last Updated</h6>
                        <p className='fs-13 text-muted'>{transformDateOnly(item.updatedAt)}</p>
                      </div>
                    </div>
                    <div className="col d-none d-lg-block">
                      <div className='py-3 d-flex align-items-center  h-100 '>
                        {
                          (item.status == 'Created') ?
                            <div className="btn btn-sm px-3 rounded-pill text-white" style={{ backgroundColor: "red" }}>Unresolve</div>
                            :
                            <div className="btn btn-sm px-3 rounded-pill text-white" style={{ backgroundColor: "#508D02" }}>Resolved</div>

                        }
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            )}
        </div>


      </div>

      {/* <div className='d-lg-none'>
        {
          selectedTicket &&
          <SupportChatOffCanvas id={selectedTicket} res={supportTicketsRes} closeSupport={closeSupport} />
        }
      </div> */}

      <div className='col-md-5 ms-3 card shadow rounded-5 overflow-hidden d-none d-lg-block '>
        {
          selectedTicket &&
          <SupportAction id={selectedTicket} res={supportTicketsRes} />
        }
        {
          !selectedTicket && <div className='bg-white rounded-4 '>
            <div className='d-flex align-items-center justify-content-between p-4' style={{ backgroundColor: "#F5F5F5" }}>
              <h5 className='text-danger fw-bold me-3'>Resolve <span className='text-black'>Tickets</span></h5>
            </div>
            <div className='p-3 text-center'>
              <img src={ticketImg} alt="" className='w-100' />
              <h5 className='my-3'>Prioritize the resolution of unresolved tickets for enhanced service provision.</h5>
              <p>Tickets resolved by you will be marked as messages from the Administrator,
                ensuring efficient management.</p>
              <img src={rodImg} alt="" className='w-100' />
            </div>
          </div>
        }
      </div>
    </div>

  )

  return (
    <div className='d-flex py-5 hide justify-content-between h-100'>

      <div className=' hide-scroll flex-grow-1  mx-4 h-100 p-3 card bg-opacity-25 bg-white shadow rounded-5' style={{ width: '60%' }}>
        <div className="rounded-4 bg-primary mx-3 p-4 border-bottom">
          <h2 className='text-white fw-bold'>Tickets</h2>
        </div>

        {/* <div className='bg-white p-4 overflow-auto h-100'>
          {supportTicketsRes.data.map((item) =>

            <div className='d-flex bg-white rounded-4 my-3 shadow' key={item.id}>

              <div className='p-4 pe-5 border-end flex-grow-1'>
                <h6 className='fw-bold'>Ticket ID</h6>
                <p className='fs-13 text-muted'>{item.id}</p>
              </div>

              <div className='p-4 pe-5 border-end flex-grow-1'>
                <h6 className='fw-bold'>Topic</h6>
                <p className='fs-13 text-muted'>{item.topic}</p>
              </div>

              <div className='p-4 pe-5 border-end flex-grow-1'>
                <h6 className='fw-bold'>Last Updated</h6>
                <p className='fs-13 text-muted'>{item.updatedAt}</p>
              </div>

              <div className='p-4 border-end d-flex align-items-center'>
                <div className="btn btn-success btn-sm px-3 rounded-pill text-white">Resolved</div>
              </div>

              <div className='d-flex p-4'>
                <div className='btn p-0' onClick={() => setSelectedTicket(item.id)}>{Icons.arrow()}</div>
              </div>

            </div>
          )}
        </div> */}

        <div className=' overflow-y-auto container p-0 flex-grow-1'>

          {supportTicketsRes.data?.map((item) =>
            <div className='row row-cols-5 d-flex    align-items-center bg-white flex-grow-1 rounded-4 m-3 shadow ' key={item.id}>

              {/* <div className="row"> */}

              <div className="col-2">
                <div className='py-3 border-end  '>
                  <div className='fw-bold'>Ticket ID</div>
                  <p className='fs-11 text-muted'>{item.id}</p>
                </div>
              </div>

              <div className="col-4">
                <div className='py-3  border-end '>
                  <h6 className='fw-bold'>Topic</h6>
                  <p className='fs-11 text-muted'>{item.topic.length > 15 ? item.topic.substring(0, 14) + "..." : item.topic}</p>
                </div>
              </div>

              <div className="col-3">

                <div className='py-3 border-end'>
                  <h6 className='fw-bold'>Last Updated</h6>
                  <p className='fs-11 text-muted'>{item.updatedAt && new Date(item.updatedAt).toLocaleDateString()}</p>
                </div>
              </div>

              <div className="col-2 ">
                <div className='py-3  border-end'>
                  {
                    (item.status == 'Created') ?
                      <div className="btn btn-sm  rounded-pill text-white" style={{ backgroundColor: "red" }}>Unresolve</div>
                      :
                      <div className="btn btn-sm  rounded-pill text-white" style={{ backgroundColor: "#508D02" }}>Resolved</div>

                  }
                </div>
              </div>

              <div className="col-1">
                <div className='py-3 text-center'>
                  <div className='btn p-0' onClick={() => setSelectedTicket(item.id)}>{Icons.arrow()}</div>
                </div>
              </div>
              {/* </div> */}

              {/* <div className='p-4 border-end w-25 '>
                <h6 className='fw-bold'>Ticket ID</h6>
                <p className='fs-13 text-muted'>{item.id}</p>
              </div>

              <div className='p-4  border-end w-25'>
                <h6 className='fw-bold'>Topic</h6>
                <p className='fs-13 text-muted'>{item.topic.length > 15 ? item.topic.substring(0, 14) + "..." : item.topic}</p>
              </div>

              <div className='p-4 pe-5 border-end flex-grow-1'>
                <h6 className='fw-bold'>Last Updated</h6>
                <p className='fs-13 text-muted'>{item.updatedAt}</p>
              </div>

              <div className='p-4 d-flex align-items-center w-25'>
                {
                  (item.status == 'Created') ?
                    <div className="btn btn-sm px-3 rounded-pill text-white" style={{ backgroundColor: "red" }}>Unresolve</div>
                    :
                    <div className="btn btn-sm px-3 rounded-pill text-white" style={{ backgroundColor: "#508D02" }}>Resolved</div>

                }
              </div>

              <div className='d-flex p-4'>
                <div className='btn p-0' onClick={() => setSelectedTicket(item.id)}>{Icons.arrow()}</div>
              </div> */}

            </div>
          )}
        </div>

      </div>

      <div style={{ width: '40%' }} className=''>
        {
          selectedTicket &&
          <SupportAction id={selectedTicket} />
        }
        {
          !selectedTicket &&
          <div className='bg-white rounded-start-5 shadow overflow-hidden h-100'>
            <div className='d-flex align-items-center justify-content-between p-4' style={{ backgroundColor: "#F5F5F5" }}>
              <h5 className='text-danger fw-bold me-3'>Resolve <span className='text-black'>Tickets</span></h5>
            </div>
            <div className='p-3 text-center'>
              <img src={ticketImg} alt="" className='w-100' />
              <h5 className='my-3'>Prioritize the resolution of unresolved tickets for enhanced service provision.</h5>
              <p>Tickets resolved by you will be marked as messages from the Administrator,
                ensuring efficient management.</p>
              <img src={rodImg} alt="" className='w-100' />
            </div>
          </div>
        }
      </div>

    </div>
  )

  return (
    <div className='d-flex py-5 hide justify-content-between h-100'>

      <div className=' hide-scroll flex-grow-1  mx-4 h-100  card shadow rounded-5 overflow-x-hidden' style={{ width: '60%' }}>
        <h2 className='fw-bold text-danger bg-light p-4'>Tickets</h2>

        <div className=' overflow-y-auto'>

          {supportTicketsRes.data?.map((item) =>
            <div className='d-flex bg-white rounded-4 m-3 shadow ' key={item.id}>

              <div className='p-4 border-end w-25 '>
                <h6 className='fw-bold'>Ticket ID</h6>
                <p className='fs-13 text-muted'>{item.id}</p>
              </div>

              <div className='p-4  border-end w-25'>
                <h6 className='fw-bold'>Topic</h6>
                <p className='fs-13 text-muted'>{item.topic.length > 15 ? item.topic.substring(0, 14) + "..." : item.topic}</p>
              </div>

              <div className='p-4 pe-5 border-end flex-grow-1'>
                <h6 className='fw-bold'>Last Updated</h6>
                <p className='fs-13 text-muted'>{item.updatedAt}</p>
              </div>

              <div className='p-4 d-flex align-items-center w-25'>
                {
                  (item.status == 'Created') ?
                    <div className="btn btn-sm px-3 rounded-pill text-white" style={{ backgroundColor: "red" }}>Unresolve</div>
                    :
                    <div className="btn btn-sm px-3 rounded-pill text-white" style={{ backgroundColor: "#508D02" }}>Resolved</div>

                }
              </div>
              <div className='d-flex p-4'>
                <div className='btn p-0' onClick={() => setSelectedTicket(item.id)}>{Icons.arrow()}</div>
              </div>

            </div>
          )}
        </div>

      </div>

      <div style={{ width: '40%' }}>
        {
          selectedTicket &&
          <SupportAction id={selectedTicket} />
        }
        {
          !selectedTicket &&
          <div className='bg-white rounded-start-5  shadow overflow-hidden'>
            <div className='d-flex align-items-center justify-content-between p-4' style={{ backgroundColor: "#F5F5F5" }}>
              <h5 className='text-danger fw-bold me-3'>Resolve <span className='text-black'>Tickets</span></h5>
            </div>
            <div className='p-3 text-center'>
              <img src={ticketImg} alt="" className='w-100' />
              <h5 className='my-3'>Prioritize the resolution of unresolved tickets for enhanced service provision.</h5>
              <p>Tickets resolved by you will be marked as messages from the Administrator,
                ensuring efficient management.</p>
              <img src={rodImg} alt="" className='w-100' />
            </div>
          </div>
        }
      </div>

    </div>
  )

  return (
    <div className='d-flex py-5 justify-content-between h-100'>

      <div className='bg-light rounded-5 hide-scroll overflow-hidden mx-4 h-100' style={{ width: '60%' }}>
        <h2 className='fw-bold text-danger p-4'>Tickets</h2>

        <div className='bg-white p-4 overflow-auto h-100'>
          {supportTicketsRes.data.map((item) =>

            <div className='d-flex bg-white rounded-4 my-3 shadow' key={item.id}>

              <div className='p-4 pe-5 border-end flex-grow-1'>
                <h6 className='fw-bold'>Ticket ID</h6>
                <p className='fs-13 text-muted'>{item.id}</p>
              </div>

              <div className='p-4 pe-5 border-end flex-grow-1'>
                <h6 className='fw-bold'>Topic</h6>
                <p className='fs-13 text-muted'>{item.topic}</p>
              </div>

              <div className='p-4 pe-5 border-end flex-grow-1'>
                <h6 className='fw-bold'>Last Updated</h6>
                <p className='fs-13 text-muted'>{item.updatedAt}</p>
              </div>

              <div className='p-4 border-end d-flex align-items-center'>
                <div className="btn btn-success btn-sm px-3 rounded-pill text-white">Resolved</div>
              </div>

              <div className='d-flex p-4'>
                <div className='btn p-0' onClick={() => setSelectedTicket(item.id)}>{Icons.arrow()}</div>
              </div>

            </div>
          )}
        </div>
      </div>

      <div style={{ width: '40%' }}>
        {
          selectedTicket &&
          <SupportAction id={selectedTicket} />
        }
        {
          !selectedTicket &&
          <div className='bg-white rounded-start-5  shadow overflow-hidden'>
            <div className='d-flex align-items-center justify-content-between p-4' style={{ backgroundColor: "#F5F5F5" }}>
              <h5 className='text-danger fw-bold me-3'>Resolve <span className='text-black'>Tickets</span></h5>
            </div>
            <div className='p-3 text-center'>
              <img src={ticketImg} alt="" className='w-100' />
              <h5 className='my-3'>Prioritize the resolution of unresolved tickets for enhanced service provision.</h5>
              <p>Tickets resolved by you will be marked as messages from the Administrator,
                ensuring efficient management.</p>
              <img src={rodImg} alt="" className='w-100' />
            </div>
          </div>
        }
      </div>

    </div>
  )
}

export default Support
