const BRAHMVIDYA_URL = process.env.BRAHMVIDYA_FRONTEND || 'https://admin.br.icgs.sgrsutility.org/'
const EDUCATION_URL = process.env.EDUCATION_FRONTEND || 'https://admin.edu.icgs.sgrsutility.org/'
const SUPERADMIN_URL = process.env.SUPER_ADMIN_BACKEND || 'https://sa.sgrsutility.in/'
const BASE_URL = process.env.EDUCATION_BACKEND || 'https://edu.icgs.sgrsutility.in/'

export default {
    BASE_URL,
    SUPERADMIN_URL, BRAHMVIDYA_URL, EDUCATION_URL,
    SUPERADMIN_ADMIN_URL: SUPERADMIN_URL + "icgsEducation/admin/",
    ADMIN_URL: BASE_URL + "admin/",
    IMAGE_URL: BASE_URL + "uploads/images/",
    SLIDER_URL: BASE_URL + "uploads/sliderImages/",
    PROFILE_PICTURE_URL: SUPERADMIN_URL + "uploads/images/profilePictures/"
}