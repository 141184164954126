import React from 'react'
import { useCreateCmsSupportMutation, useCreateTechSupportMutation, useGetCmsSupportQuery, useGetPolicyQuery, useGetTechSupportQuery, useGetTermsQuery, useGetUserDetailsQuery, useUpdateCmsSupportMutation, useUpdateTechSupportMutation } from '../../services/adminSlice'
import Loading from '../../components/ui/Loading'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import Api from '../../constants/Api'
import Image from '../../components/ui/Image'
import Icons from '../../components/ui/Icons'
import Input from '../../components/form/Input'
import SuccessModal from "../../components/modals/SuccessModal";
import ViewEditPolicySettingModal from '../../components/modals/ViewEditPolicySettingModal'


const SettingsV1 = () => {

    const response = useGetUserDetailsQuery()
    const getTerms = useGetTermsQuery()
    const getTech = useGetTechSupportQuery()
    const getCms = useGetCmsSupportQuery();
    const getPolicy = useGetPolicyQuery();
    const [updateTech, updateTechRes] = useUpdateTechSupportMutation()
    const [createTech, createTechRes] = useCreateTechSupportMutation()

    const [updateCms, updateCmsRes] = useUpdateCmsSupportMutation()
    const [createCms, createCmsRes] = useCreateCmsSupportMutation()


    const handleTechSubmitUpdate = (e) => {
        e.preventDefault()
        let id = e.target['Id'].value;
        const body = {
            phone: e.target['Phone'].value,
            email: e.target['Email'].value
        }
        updateTech({ id, body })
            .then(() => {

                response.refetch()

            })
    }

    const handleTechSubmit = (e) => {
        e.preventDefault()
        const body = {
            phone: e.target['Phone'].value,
            email: e.target['Email'].value
        }
        createTech(body)
            .then(() => {

                response.refetch()

            })
    }


    const handleCmsSubmitUpdate = (e) => {
        e.preventDefault()
        let id = e.target['Id'].value;
        const body = {
            phone: e.target['Phone'].value,
            email: e.target['Email'].value
        }
        updateCms({ id, body })
            .then(() => {

                response.refetch()

            })
    }

    const handleCmsSubmit = (e) => {
        e.preventDefault()
        const body = {
            phone: e.target['Phone'].value,
            email: e.target['Email'].value
        }
        createCms(body)
            .then(() => {

                response.refetch()

            })
    }



    if (response.isLoading || getTerms.isLoading || getTech.isLoading || getCms.isLoading || getPolicy.isLoading) return <Loading />
    if (response.isError) return <ApiErrorModal response={response} />
    if (getTerms.isError) return <ApiErrorModal response={getTerms} />

    const user = response.data

    console.log("eeee",user.profilePicture.id)

    return (
        <div class="h-100 p-4" style={{ zIndex: 5 }}>

            {updateTechRes.isSuccess && <SuccessModal label={"Email & Contact Changed!"} message={"Information  has been changed."} closeTo={"/settingV1"} />}
            {updateTechRes.isError && <ApiErrorModal response={updateTechRes} />}

            {updateCmsRes.isSuccess && <SuccessModal label={"Email & Contact Changed!"} message={"Information  has been changed."} closeTo={"/settingV1"} />}
            {updateCmsRes.isError && <ApiErrorModal response={updateCmsRes} />}

            <section class="section profile bg-white rounded-5 h-100 overflow-auto">
                <div class="row">
                    <div class="col-xl-4">
                        <div class="card  border-0" >
                            <div class="card-body profile-card pt-4 d-flex flex-column align-items-center" >
                                <div class="w-100">
                                    {
                                        user.branch.profilePicture ?
                                            <Image
                                                src={Api.PROFILE_PICTURE_URL + user.branch.profilePicture.id + user.branch.profilePicture.ext}
                                                alt="profile"
                                                className="w-100 rounded-5"
                                                style={{ height: 150, marginBottom: -50, }}
                                                icon={() => Icons.defaultUser("", { width: "100%", height: "100%" })}
                                            />
                                            :
                                            <img alt="Profile" class="w-100 rounded-5"
                                                src={"https://i.imgur.com/O1VTOTb.png"} style={{ height: 150, marginBottom: -50, }} />

                                    }
                                    {/* <Image
                                        src={"https://i.imgur.com/O1VTOTb.png"}
                                        alt="profile"
                                        className="w-100 rounded-5"
                                        style={{ height: 150, marginBottom: -50, }}
                                        icon={() => Icons.defaultUser("", { width: "100%", height: "100%" })}
                                    /> */}

                                </div>
                                {/* <img alt="Profile" class="rounded-circle"
                                    src={"https://i.imgur.com/O1VTOTb.png"} /> */}

                                <Image
                                    src={Api.PROFILE_PICTURE_URL + user.profilePicture.id + user.profilePicture.ext}
                                    alt="profile"
                                    className="rounded-circle fs-6 bg-white border border-5 border-white"
                                    style={{ height: 80, width: 80 }}
                                    icon={() => Icons.defaultUser("", { width: "100%", height: "100%" })}
                                />

                                <h2>{response.data.name}</h2>
                                <h3>{response.data.post}</h3>
                            </div>

                            <div class="card border-0">
                                <hr class="mx-4" />

                                <div className='d-flex flex-column gap-2'>
                                    {
                                        getTech.data.length > 0 ?
                                            <form onSubmit={handleTechSubmitUpdate}>

                                                <div class="p-4">
                                                    <div className="d-flex align-items-center mb-4">
                                                        <div className="fw-bold">{Icons.gear("fs-2")}</div>
                                                        <div className="ms-3 me-5">for technical support</div>
                                                        <button type='submit' className='btn btn-primary mt-3 '>Update</button>
                                                    </div>
                                                    <div class="fw-bold">Call Us</div>
                                                    <Input type={'number'} inputClass={'border-0 bg-transparent fs-6'} name={'Phone'} labelName={''} defaultValue={getTech?.data[0]?.phone} />
                                                    <div class="fw-bold mt-3">Email Us</div>
                                                    <Input type={'text'} inputClass={'border-0 bg-transparent fs-6'} name={'Email'} labelName={''} defaultValue={getTech?.data[0]?.email} />
                                                    <Input type={'hidden'} name={'Id'} defaultValue={getTech?.data[0]?.id} />
                                                </div>



                                            </form>
                                            :
                                            <form onSubmit={handleTechSubmit}>

                                                <div class="p-4">
                                                    <div className="d-flex align-items-center mb-4">
                                                        <div className="fw-bold">{Icons.gear("fs-2")}</div>
                                                        <div className="ms-3 me-5">for technical support</div>
                                                        <button type='submit' className='btn btn-primary mt-3'>Create</button>
                                                    </div>
                                                    <div class="fw-bold">Call Us</div>
                                                    <Input type={'number'} inputClass={'border-0 bg-transparent fs-6'} name={'Phone'} labelName={''} defaultValue={getTech?.data[0]?.phone} />

                                                    <div class="fw-bold mt-3  ">Email Us</div>
                                                    <Input type={'text'} inputClass={'border-0 bg-transparent fs-6'} name={'Email'} labelName={''} defaultValue={getTech?.data[0]?.email} />

                                                </div>

                                                {/* <Input type={'number'} name={'Phone'} labelName={'Call Us'} defaultValue={getTech?.data[0]?.phone} />
                        <Input type={'text'} name={'Email'} labelName={'Email Us'} defaultValue={getTech?.data[0]?.email} />
*/}
                                            </form>
                                    }

                                </div>


                                <div className='d-flex flex-column gap-2'>
                                    {

                                        getCms?.data?.length > 0 ?
                                            <form onSubmit={handleCmsSubmitUpdate}>

                                                <div class="px-4 ">
                                                    <div className="d-flex align-items-center mb-4">
                                                        <div className="fw-bold">{Icons.gear("fs-2")}</div>
                                                        <div className="ms-3 me-5">for CMS support</div>
                                                        <button type='submit' className='btn btn-primary mt-3 '>Update</button>
                                                    </div>
                                                    <div class="fw-bold">Call Us</div>
                                                    <Input type={'number'} inputClass={'border-0 bg-transparent fs-6'} name={'Phone'} labelName={''} defaultValue={getCms?.data[0]?.phone} />
                                                    <div class="fw-bold mt-3">Email Us</div>
                                                    <Input type={'text'} inputClass={'border-0 bg-transparent fs-6'} name={'Email'} labelName={''} defaultValue={getCms?.data[0]?.email} />
                                                    <Input type={'hidden'} name={'Id'} defaultValue={getCms?.data[0]?.id} />
                                                </div>



                                            </form>
                                            :
                                            <form onSubmit={handleCmsSubmit}>

                                                <div class="p-4">
                                                    <div className="d-flex align-items-center mb-4">
                                                        <div className="fw-bold">{Icons.gear("fs-2")}</div>
                                                        <div className="ms-3 me-5">for CMS support</div>
                                                        <button type='submit' className='btn btn-primary mt-3'>Create</button>
                                                    </div>
                                                    <div class="fw-bold">Call Us</div>
                                                    <Input type={'number'} inputClass={'border-0 bg-transparent fs-6'} name={'Phone'} labelName={''} defaultValue={getCms?.data[0]?.phone} />

                                                    <div class="fw-bold mt-3  ">Email Us</div>
                                                    <Input type={'text'} inputClass={'border-0 bg-transparent fs-6'} name={'Email'} labelName={''} defaultValue={getCms?.data[0]?.email} />

                                                </div>

                                                {/* <Input type={'number'} name={'Phone'} labelName={'Call Us'} defaultValue={getTech?.data[0]?.phone} />
                        <Input type={'text'} name={'Email'} labelName={'Email Us'} defaultValue={getTech?.data[0]?.email} />
*/}
                                            </form>
                                    }

                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-xl-8">
                        <div class="card border-0">
                            <div class="card-body p-0">
                                <ul class="nav nav-tabs nav-tabs-bordered position-sticky sticky-top bg-white settings-tabs"
                                    role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button aria-selected="false" class="nav-link active" data-bs-target="#primary-details"
                                            data-bs-toggle="tab" role="tab" tabindex="-1">Primary Details</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button aria-selected="true" class="nav-link" data-bs-target="#contact-details"
                                            data-bs-toggle="tab" role="tab">Contact</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button aria-selected="false" class="nav-link" data-bs-target="#credentials"
                                            data-bs-toggle="tab" role="tab" tabindex="-1">Credentials</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button aria-selected="false" class="nav-link" data-bs-target="#twofa"
                                            data-bs-toggle="tab" role="tab" tabindex="-1">2FA</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button aria-selected="false" class="nav-link" data-bs-target="#terms"
                                            data-bs-toggle="tab" role="tab" tabindex="-1">Terms</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button aria-selected="false" class="nav-link" data-bs-target="#policy"
                                            data-bs-toggle="tab" role="tab" tabindex="-1">IMS Policy</button>
                                    </li>
                                </ul>
                                <div class="tab-content p-3">
                                    <div class="tab-pane fade profile-overview active show" id="primary-details"
                                        role="tabpanel">
                                        <h5 class="card-title">
                                            About
                                        </h5>
                                        <p class="fst-italic">
                                            Sunt est soluta temporibus accusantium neque nam maiores cumque temporibus. Tempora
                                            libero non est unde veniam est qui dolor. Ut sunt iure rerum quae quisquam autem
                                            eveniet perspiciatis odit. Fuga sequi sed ea saepe at unde.
                                        </p>
                                        <h5 class="card-title">
                                            Profile Details
                                        </h5>
                                        <div class="row">
                                            <div class="col-lg-3 col-md-4 label text-black fw-bold">
                                                Name
                                            </div>
                                            <div class="col-lg-9 col-md-8">{response.data.name}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-3 col-md-4 label text-black fw-bold">
                                                Branch
                                            </div>
                                            <div class="col-lg-9 col-md-8">{response.data.branch.branchName}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-3 col-md-4 label text-black fw-bold">
                                                Post
                                            </div>
                                            <div class="col-lg-9 col-md-8">{response.data.post}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-3 col-md-4 label text-black fw-bold">
                                                SUID
                                            </div>
                                            <div class="col-lg-9 col-md-8">{response.data.sUid}</div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade profile-overview" id="contact-details" role="tabpanel">
                                        <h5 class="card-title">
                                            Contact Details
                                        </h5>
                                        <div class="row">
                                            <div class="col-lg-3 col-md-4 label text-black fw-bold">
                                                Email
                                            </div>
                                            <div class="col-lg-9 col-md-8">{response.data.email}</div>
                                        </div>
                                        <p class=" text-muted mb-3">(This email will serve as the primary means for communicating
                                            important details and updates to enhance your overall experience.)</p>
                                        <div class="row">
                                            <div class="col-lg-3 col-md-4 label text-black fw-bold">
                                                Contact Number
                                            </div>
                                            <div class="col-lg-9 col-md-8">{response.data.phoneNumber}</div>
                                        </div>
                                        <p class=" text-muted mb-3">(All crucial communications will be conveyed through this
                                            designated contact number.)</p>
                                    </div>
                                    <div class="tab-pane fade profile-overview" id="credentials" role="tabpanel">

                                        <form>
                                            <div class="row mb-3">
                                                <label class="col-md-4 col-lg-3 col-form-label text-black fw-bold" for="email">Email</label>
                                                <div class="col-md-8 col-lg-9">
                                                    <input class="form-control" id="email" name="email" type="email" />
                                                </div>
                                            </div>
                                            <p class=" text-muted mb-3">If you suspect that your user ID is at risk of unauthorized
                                                access, please promptly contact the SGRS Utility Service Administrator to update
                                                your user ID. Failure to uphold account security measures may result in
                                                temporary or permanent suspension from all Utility services. Please refer to the
                                                terms and conditions for further information.</p>
                                            <p class=" text-muted mb-3">To update your ID and password, enter your current password,
                                                input your new password, undergo authentication via 2FA, and then proceed to
                                                change your password.</p>
                                            <div class="row mb-3">
                                                <label class="col-md-4 col-lg-3 col-form-label text-black fw-bold" for="currentPassword ">Current
                                                    Password</label>
                                                <div class="col-md-8 col-lg-9">
                                                    <input class="form-control" id="currentPassword" name="password"
                                                        type="password" />
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <label class="col-md-4 col-lg-3 col-form-label text-black fw-bold" for="newPassword">New
                                                    Password</label>
                                                <div class="col-md-8 col-lg-9">
                                                    <input class="form-control" id="newPassword" name="newpassword"
                                                        type="password" />
                                                </div>
                                            </div>
                                            <div class="text-end">
                                                <button class="btn btn-primary " type="submit">Change Password</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="tab-pane fade profile-overview" id="twofa" role="tabpanel">

                                        <form>
                                            <div class="row mb-3">
                                                <label class="col-md-4 col-lg-3 col-form-label text-black fw-bold" for="mail">Email</label>
                                                <div class="col-md-8 col-lg-9">
                                                    <input class="form-control" id="mail" name="mail" type="email" />
                                                </div>
                                            </div>
                                            <p class=" text-muted mb-3">(This email will serve as your secondary factor for
                                                authentication.)</p>
                                            <div class="row mb-3">
                                                <label class="col-md-4 col-lg-3 col-form-label text-black fw-bold" for="contactnumber">Contact
                                                    Number</label>
                                                <div class="col-md-8 col-lg-9">
                                                    <input class="form-control" id="numbermob" name="mobnumber" type="tel" />
                                                </div>
                                            </div>
                                            <p class=" text-muted mb-3">(This contact number will be employed as your secondary factor
                                                for authentication. Please note that 2FA via contact number is currently
                                                unavailable.)</p>
                                            <div class="text-end">
                                                <button class="btn btn-primary" type="submit">Save Settings</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="tab-pane fade profile-overview" id="terms" role="tabpanel">

                                        <div className="d-flex gap-5">
                                            <div className='fw-bold text-danger'>Terms and Conditions</div>


                                        </div>

                                        <div className='flex-grow-1 bg-white pt-4 px-3' dangerouslySetInnerHTML={{ __html: getTerms?.data[0]?.content }}>



                                        </div>
                                        <div className='text-start fw-bold p-3'>
                                            Accepted <span className='text-dark fs-11'>
                                                {getTerms?.data[0]?.updatedAt ? new Date(getTerms.data[0].updatedAt).toLocaleDateString() : ''}
                                            </span>
                                        </div>
                                    </div>


                                </div>

                                <div
                                    class="tab-pane fade profile-overview"
                                    id="policy"
                                    role="tabpanel"
                                >

                                    <div className="d-flex gap-5">
                                        <div className='fw-bold text-danger'>Policy</div>


                                        {
                                            getPolicy?.data?.length > 0 ?
                                                <ViewEditPolicySettingModal isCreate={false} id={getPolicy.data[0].id} refetch={getPolicy.refetch} />
                                                :
                                                <ViewEditPolicySettingModal isCreate={true} id={1} refetch={getPolicy.refetch} />
                                        }
                                    </div>

                                    <div className='flex-grow-1 bg-white pt-4 px-3' dangerouslySetInnerHTML={{ __html: getPolicy?.data[0]?.content }}>



                                    </div>
                                    <div className='text-start fw-bold p-3'>
                                        Accepted <span className='text-dark fs-11'>
                                            {getPolicy?.data[0]?.updatedAt ? new Date(getPolicy.data[0].updatedAt).toLocaleDateString() : ''}
                                        </span>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )

}

export default SettingsV1