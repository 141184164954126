import React from 'react'
import Input from '../../components/form/Input'
import { useCreateArchiveMutation } from '../../services/archiveSlice'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import ImageInput from '../../components/form/ImageInput'
import Button from '../../components/form/Button'
import { useNavigate } from 'react-router-dom'
import FormattedInput from '../../components/form/FormattedInput'

const CreateArchive = () => {

    const [createArchive, createArchiveRes] = useCreateArchiveMutation()

    const navigate = useNavigate()

    const onSubmit = (event) => {
        event.preventDefault();

        const form = event.target;
        if (form.checkValidity()) {
            const files = form['images'].files
            let body = new FormData();
            body.append("title", form['Title'].value);
            body.append("content", form['formattedInput'].value);
            for (let index = 0; index < files.length; index++) body.append("images", files[index])
            createArchive(body).then((res) => {
                if (!res.error) {
                    navigate(-1)
                }
            })

        }


        form.classList.add('was-validated')
    }

    return (
        <div className='p-5'>

            {createArchiveRes.isError && <ApiErrorModal response={createArchiveRes} />}

            <h2 className='fw-bold text-danger'>Educational  Archives</h2>
            <p className='mb-5'>This all are the latest updates on our school</p>

            <form className="" onSubmit={onSubmit} noValidate>
                <div className='card p-3 flex-row align-items-center'>
                    <div className='flex-grow-1'>
                        <Input name={'Title'} containerClass={'mb-3'} required />
                        <FormattedInput />
                        <div>
                            <Button
                                className="btn btn-primary rounded-pill px-5 text-white mt-3"
                                res={createArchiveRes}
                                loadingLabel={"Creating"}
                            >
                                Create new Archive
                            </Button>

                        </div>
                    </div>
                    <div className='m-3' style={{ width: 250 }}>
                        <ImageInput
                            name={"images"}
                            labelName={'Browse file to upload'}
                            labelClass={'p-5 rounded-3 text-primary'}
                            labelStyle={{ borderStyle: 'dashed', borderColor: '#D0DBF8' }}
                            multiple
                            required
                        >
                            <div className='invalid-feedback'>*Select Image</div>
                        </ImageInput>
                    </div>
                </div>
            </form>

        </div>
    )
}

export default CreateArchive