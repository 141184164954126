import React, { useState } from 'react'
import Button from '../form/Button';
import { getApiErrorMessage } from '../../utility/errorMessages';
import Check from '../form/Check';
import { useDeleteDownloadableMutation, useGetDownloadableByIdQuery, useUpdateDownloadableMutation } from '../../services/downloadablesSlice';
import Input from '../form/Input';
import Select from '../form/Select';

const ViewEditInfoModal = ({ refetch, id, isCreate }) => {

    const modalId = "viewEditInfoModal" + id


    const [editorContent, setEditorContent] = useState('');

    const [updateDownloadable, updateDownloadableRes] = useUpdateDownloadableMutation();
    const downloadableRes = useGetDownloadableByIdQuery(id)

    const [formEnabled, setFormEnabled] = useState(false)

    const type = ["document", "video", "audio", "picture"]
    const containerClass = "mb-3"

    const [deleteDownloadable, deleteDownloadableRes] = useDeleteDownloadableMutation()


    const handleEditorContentChange = (content) => setEditorContent(content)

    const handleDelete = () => {
        const isConfirmed = window.confirm("Are you sure you want to delete this item?");
        if (isConfirmed) {
            deleteDownloadable(id).then((res) => {
                if (!res.error) {
                    document.getElementById(modalId + 'CloseBtn').click();
                    refetch();
                }
            });
        } else {
            console.log("Deletion canceled by user");
        }
    };


    const onSubmit = (event) => {
        event.preventDefault();
        const form = event.target

        if (form.checkValidity()) {
            const body = {
                name: form['File Name'].value,
                type: form['File Type'].value,
                link: form['File Link'].value
            }

            if (isCreate) {

            }
            else {
                updateDownloadable({ id, body }).then((res) => {
                    if (!res.error) {
                        document.getElementById(modalId + 'CloseBtn').click();
                        refetch();
                        form.classList.remove('was-validated')
                    }
                })
            }

        }
        else form.classList.add('was-validated')

    }

    // useEffect(() => {
    //     if (res.isSuccess && res.data) {
    //         setEditorContent(res.data.memo)
    //     }
    // }, [res.isSuccess, res.data])

    // if (res.isLoading) return <Loading />
    // if (res.isError) return <ApiErrorModal response={res} />



    return (
        <>



            {/* <div className="btn btn-secondary btn-sm text-white rounded-pill px-3" id={modalId + "Button"} data-bs-toggle="modal" data-bs-target={"#" + modalId}>View</div> */}

            <div className="modal fade text-dark" id={modalId} tabIndex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">
                <form className="modal-dialog modal-dialog-centered modal-lg" onSubmit={onSubmit} noValidate>
                    <div className="modal-content bg-white rounded-4">
                        <div className="rounded-4">
                            <div className="modal-header py-0 border-0 position-relative">
                                <div className='px-5 bg-primary rounded-top-5 rounded-end-5 text-white py-2 position-absolute start-0'>Edit Downloadables</div>
                            </div>
                            <div className="modal-body text-dark p-5">
                                <h5 className="fw-bold mb-3">Primary Details</h5>


                                <Input name={"File Name"} labelName={"File Name"} inputClass={'bg-white'} containerClass={containerClass} defaultValue={downloadableRes.data?.name} disabled={!formEnabled} required />
                                <Select name={"File Type"} labelName={"File Type"} options={type} inputClass={'bg-white'} containerClass={containerClass} selectedValue={downloadableRes.data?.type} disabled={!formEnabled} />
                                <Input name={"File Link"} labelName={"File Link"} inputClass={'bg-white'} containerClass={containerClass} defaultValue={downloadableRes.data?.link} disabled={!formEnabled} required />



                            </div>
                            <div className="modal-footer justify-content-end border-0">
                                {updateDownloadable.isError &&
                                    <div className='me-auto fw-bold text-danger'>
                                        {getApiErrorMessage(updateDownloadable).label}: {getApiErrorMessage(updateDownloadable).message}
                                    </div>
                                }

                                <Check name={"Check to edit"} setChecked={setFormEnabled} containerClass={"me-3"} required />


                                <Button className='btn btn-warning rounded-pill' type="button" disabled={!formEnabled} onClick={handleDelete}>Delete</Button>

                                <Button
                                    className="btn btn-secondary rounded-pill px-4 shadow-sm"
                                    loadingLabel="Updating"
                                    res={updateDownloadable}
                                    disabled={!formEnabled}
                                    type='submit'
                                >
                                    Update
                                </Button>

                            </div>
                        </div>
                    </div>
                </form >
            </div >

        </>
    )
}

export default ViewEditInfoModal