import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useGetNoticeByIdQuery, useGetNoticesQuery } from '../../services/noticeSlice'

const ViewAllNoticeModal = ({ id }) => {

    const modalId = "viewAllNoticeModal"
    const navigate = useNavigate()
    const noticeRes = useGetNoticesQuery()

    // const onDelete = () => {
    //     deleteAssignment(id).then((res) => {
    //         if (!res.error) {
    //             document.getElementById(modalId + 'CloseBtn').click()
    //             refetch()
    //         }
    //     })
    // }

    function getMonthName(monthIndex) {
        const months = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
        ];
        return months[monthIndex];
      }

    return (
        <>
            {/* <Link type="button" className="text-danger fw-bold" data-bs-toggle="modal" data-bs-target={"#" + modalId}>
                See Now
            </Link> */}

            <div className="modal fade" id={modalId} tabindex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id={modalId + "Label"}>{noticeRes.data?.title}</h1>
                            <button id={modalId + "CloseBtn"} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {noticeRes?.data?.map(item =>
                                <div key={item.id}>
                                    <div className='d-flex align-items-center'>
                                        <div className='bg-primary py-2 px-3 rounded-4 text-light justify-content-center me-3'>
                                            <h3>{new Date(item.createdAt).getDate()}</h3>
                                            <p className='fs-13'>{getMonthName(new Date(item.createdAt).getMonth())}</p>
                                        </div>
                                        <p className='fw-bold'>
                                            {item.title}
                                            <Link to={'/notice/' + item.id} className='text-danger'>View</Link>
                                        </p>
                                    </div>
                                    <hr className='text-light' />
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary text-white" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewAllNoticeModal