
import React, { useEffect, useState } from 'react';
import { useGetCircularByIdQuery, useUpdateCircularMutation } from '../../services/circularSlice';
import { useParams } from 'react-router-dom';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import Loading from '../../components/ui/Loading';
import Button from '../../components/form/Button';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';

const EditCircular = () => {
    const { id } = useParams();
    const [updateCircular, updateCircularRes] = useUpdateCircularMutation();
    const response = useGetCircularByIdQuery(id);
    const [kanyaGurukul, setKanyaGurukul] = useState(false);
    const [schoolManager, setSchoolManager] = useState(false);
    const [hostelManager, setHostelManager] = useState(false);
    const [principal, setPrincipal] = useState(false);
    const [select, setSelect] = useState('General');

    const onSubmit = (event) => {
        event.preventDefault();
        const form = event.target;
        const body = {
            title: form['Circular Title'].value,
            content: form['Content'].value,
            kanyaGurukul: form['kanyagurukul'].checked,
            schoolManager: form['schoolmanager'].checked,
            hostelManager: form['hostelmanager'].checked,
            principal: form['principal'].checked,
            expiryDate: form['Date'].value,
            link: form['Attachment'].value,
        };

        updateCircular({ id, body });
    };

    useEffect(() => {
        if (response.isSuccess) {
            setKanyaGurukul(response.data.kanyaGurukul)
            setSchoolManager(response.data.schoolManager)
            setHostelManager(response.data.hostelManager)
            setPrincipal(response.data.principal)
        }
    }, [response.isSuccess])

    useEffect(() => {
        console.log('objecteeeee')
        if (kanyaGurukul && schoolManager && hostelManager && principal) {
            setSelect('General')
        }
        else {
            console.log('abc')
            setSelect('User-specific')
        }
    }, [kanyaGurukul, schoolManager, hostelManager, principal])

    useEffect(() => {
        if (select === 'General') {
            setKanyaGurukul(true)
            setSchoolManager(true)
            setHostelManager(true)
            setPrincipal(true)
        }
    }, [select])

    if (response.isLoading) return <Loading />;
    if (response.isError) return <ApiErrorModal response={response} />;

    return (
        <div>
            {updateCircularRes.isError && <ApiErrorModal response={updateCircularRes} />}
            <form className="" onSubmit={onSubmit}>
                <div className='bg-white rounded-4 p-5'>
                    <Input name={'Circular Title'} containerClass={'mb-3'} defaultValue={response.data.title} required />
                    <Input name={"Content"} defaultValue={response.data.content} />


                    <Select labelName={"Type"} options={['General', 'User-specific']} value={select} setValue={(value) => setSelect(value)} />
                    

                    <div className="form-check">
                        <div className='row row-cols-3'>

                            <div className='col'>
                                <input className="form-check-input" type="checkbox" checked={kanyaGurukul} onChange={e => setKanyaGurukul(e.target.checked)} id="c2" name="kanyagurukul" />
                                <label className="form-check-label fs-13" htmlFor="c2">
                                    Kanya gurukul
                                </label>
                            </div>
                            <div className='col'>
                                <input className="form-check-input" type="checkbox" checked={schoolManager} onChange={e => setSchoolManager(e.target.checked)} id="c3" name="schoolmanager" />
                                <label className="form-check-label fs-13" htmlFor="c3">
                                    School manager
                                </label>
                            </div>

                            <div className='col'>
                                <input className="form-check-input" type="checkbox" checked={hostelManager} onChange={e => setHostelManager(e.target.checked)} id="c5" name="hostelmanager" />
                                <label className="form-check-label fs-13" htmlFor="c5">
                                    Hostel manager
                                </label>
                            </div>
                            <div className='col'>
                                <input className="form-check-input" type="checkbox" checked={principal} onChange={e => setPrincipal(e.target.checked)} id="c6" name="principal" />
                                <label className="form-check-label fs-13" htmlFor="c6">
                                    Principal
                                </label>
                            </div>
                        </div>
                    </div>
                    <Input name={'Date'} type={'date'} containerClass={'my-3'} defaultValue={response.data.expiryDate.split('T')[0]} required />
                    <Input name={'Attachment'} containerClass={'my-3'} defaultValue={response.data.link} />
                    <div>
                        <Button
                            className="btn btn-primary rounded-pill my-3"
                            res={updateCircularRes}
                            loadingLabel={"Updating"}
                        >
                            Update the Circular
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default EditCircular;
