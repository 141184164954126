import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCirculars: builder.query({ query: () => `circular`, keepUnusedDataFor: 0 }),
        getCircularById: builder.query({ query: (id) => `circular?id=${id}`, keepUnusedDataFor: 0 }),
        createCircular: builder.mutation({ query: (body) => ({ url: `circular`, method: 'POST', body }) }),
        updateCircular: builder.mutation({ query: ({ id, body }) => ({ url: `circular?id=${id}`, method: 'PATCH', body }) }),
        deleteCircular: builder.mutation({ query: (id) => ({ url: `circular?id=${id}`, method: 'DELETE' }) }),

        
        getMarkReadCircular: builder.query({ query: () => `circular/markAsRead`, keepUnusedDataFor: 0 }),

    })
})

export const {
    useGetCircularsQuery,
    useGetCircularByIdQuery,
    useCreateCircularMutation,
    useUpdateCircularMutation,
    useDeleteCircularMutation,
    useGetMarkReadCircularQuery,
} = extendedApiSlice
