import React from 'react'
import Input from '../../components/form/Input'
import { useCreateNoticeMutation } from '../../services/noticeSlice'
import SuccessModal from '../../components/modals/SuccessModal'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import FormattedInput from '../../components/form/FormattedInput'

const CreateNotice = () => {

    const [createNotice, createNoticeRes] = useCreateNoticeMutation()

    const onSubmit = (e) => {
        e.preventDefault()

        const form = e.target;

        if (form.checkValidity()) {
            const body = {
                title: form['Title'].value,
                date: form['Date'].value,
                content: form['Content'].value,
            }

            createNotice(body)
                .then((res) => {
                    if (!res.error) {
                        form.reset()
                        form.classList.remove('was-validated')
                    }
                })
        }
        else form.classList.add('was-validated')
    }

    return (
        <div className='p-5'>

            {createNoticeRes.isSuccess && <SuccessModal label={"Added!"} message={"Notice has been added."} to={{ link: "/", text: "Go to Dashboard" }} closeTo={"/"} />}
            {createNoticeRes.isError && <ApiErrorModal response={createNoticeRes} />}

            <div>
                <form className="" onSubmit={onSubmit} noValidate>
                    <Input name={'Title'} containerClass={'mb-3'} required />
                    <Input name={'Date'} type={'date'} containerClass={'mb-3'} required />
                    <FormattedInput name={"Content"} />
                    <button className="btn btn-primary px-5 rounded-pill text-white my-3">Publish</button>
                </form>
            </div>
        </div>
    )
}

export default CreateNotice