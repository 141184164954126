import React, { useEffect, useRef, useState } from 'react'

const ImageInput = ({ name, labelName, labelClass, labelStyle, containerClass, defaultValue, children, ...attributes }) => {

    const [files, setFiles] = useState([])
    const fileInputRef = useRef(null);

    const containerClassName = "d-flex flex-column align-items- fs-13 " + (containerClass ? containerClass : "")
    const labelClassName = '' + (labelClass ? labelClass : "")

    useEffect(() => {
        const inputElement = fileInputRef.current;
        const form = inputElement?.form;

        if (form) {
            const handleReset = () => setFiles([]);
            form.addEventListener('reset', handleReset);

            // Cleanup the event listener
            return () => {
                form.removeEventListener('reset', handleReset);
            };
        }
    }, []);

    return (
        <div className={containerClassName}>
            {
                files.length > 0 ?
                    <div className="container">
                        <div className="row row-cols-4 g-2">
                            {
                                files.map((file) =>
                                    <div className="col">
                                        <label htmlFor={name}>
                                            <img
                                                src={URL.createObjectURL(file)} className='object-fit-cover' alt="" style={{ height: 50, width: 50 }} />
                                        </label>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    :
                    defaultValue?.length > 0 ?
                        <div className="container">
                            <div className="row row-cols-4 g-2">
                                {
                                    defaultValue.map((src) =>
                                        <div className="col">
                                            <label htmlFor={name}>
                                                <img
                                                    src={src} className='object-fit-cover' alt="" style={{ height: 50, width: 50 }} />
                                            </label>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        :
                        <label htmlFor={name} className={labelClassName} style={labelStyle ? labelStyle : {}}>{labelName ? labelName : "Select Image"}</label>
            }

            <input
                type={"file"}
                className={"d-none"}
                id={name}
                name={name}
                ref={fileInputRef}
                onChange={(e) => setFiles(Array.from(e.target.files))}
                {...attributes}
            />

            {children}
        </div>
    )
}

export default ImageInput
