import React, { useEffect, useState } from "react";
import Input from "../../components/form/Input";
import {
  useCreateCircularMutation,
  useGetCircularsQuery,
} from "../../services/circularSlice";
import SuccessModal from "../../components/modals/SuccessModal";
import ApiErrorModal from "../../components/modals/ApiErrorModal";
import Loading from "../../components/ui/Loading";
import CircularDeleteModal from "../../components/modals/deleteModals/CircularDeleteModal";
import { Link } from "react-router-dom";
import { useGetSliderQuery } from "../../services/sliderSlice";
import Button from "../../components/form/Button";
import Slider from "../../components/Slider";
import FormattedInput from "../../components/form/FormattedInput";
import Select from "../../components/form/Select";

const Circulars = () => {
  const [createCircular, createCircularRes] = useCreateCircularMutation();
  const response = useGetCircularsQuery();
  const sliderRes = useGetSliderQuery();

  const [kanyaGurukul, setKanyaGurukul] = useState(false);
  const [schoolManager, setSchoolManager] = useState(false);
  const [hostelManager, setHostelManager] = useState(false);
  const [principal, setPrincipal] = useState(false);
  const [select, setSelect] = useState("General");

  const onSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    if (form.checkValidity()) {
      createCircular({
        title: form["Circular Title"].value,
        content: form["formattedInput"].value,
        kanyaGurukul: form["kanyagurukul"].checked,
        schoolManager: form["schoolmanager"].checked,
        hostelManager: form["hostelmanager"].checked,
        principal: form["principal"].checked,
        expiryDate: form["Date"].value,
        link: form["Attachment"].value,
      }).then((res) => {
        if (!res.error) {
          response.refetch();
        }
      });
    }

    form.classList.add("was-validated");
  };

  // useEffect(() => {
  //   if (response.isSuccess) {
  //     setKanyaGurukul(response.data.kanyaGurukul)
  //     setSchoolManager(response.data.schoolManager)
  //     setHostelManager(response.data.hostelManager)
  //     setPrincipal(response.data.principal)
  //   }
  // }, [response.isSuccess])

  useEffect(() => {
    if (kanyaGurukul && schoolManager && hostelManager && principal) {
      setSelect("General");
    } else {
      console.log("abc");
      setSelect("User-specific");
    }
  }, [kanyaGurukul, schoolManager, hostelManager, principal]);

  useEffect(() => {
    if (select === "General") {
      setKanyaGurukul(true);
      setSchoolManager(true);
      setHostelManager(true);
      setPrincipal(true);
    }
  }, [select]);

  if (response.isLoading || sliderRes.isLoading) return <Loading />;
  if (response.isError) return <ApiErrorModal response={response} />;
  if (sliderRes.isError) return <ApiErrorModal response={sliderRes} />;

  return (
    <div className="p-4 h-100">
      {createCircularRes.isSuccess && (
        <SuccessModal message={"Circular has been created."} />
      )}
      {createCircularRes.isError && (
        <ApiErrorModal response={createCircularRes} />
      )}

      <div className="row d-flex h-100 hide-scroll">
        <div className="col-8 overflow-auto h-100 ">
          <Slider type="Circulars" />

          <div className="">
            {response?.data
              ?.slice(0)
              .reverse()
              .map((item, i) => (
                <div
                  className="p-4 bg-white rounded-4 shadow mb-3"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapseContent${i}`}
                  aria-expanded="false"
                  aria-controls={`collapseContent${i}`}
                  key={i}
                >
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5
                      className="fw-bold"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapseContent${i}`}
                      aria-expanded="false"
                      aria-controls={`collapseContent${i}`}
                    >
                      {item.title}
                    </h5>
                    <div className="btn btn-primary text-white rounded-pill">
                      {new Date(item.updatedAt).toLocaleDateString(undefined, {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </div>
                  </div>

                  <div
                    className="text-muted overflow-hidden"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapseContent${i}`}
                    aria-expanded="false"
                    aria-controls={`collapseContent${i}`}
                    style={{ maxHeight: "1.2em" }}
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  />

                  {/* <div className='d-flex justify-content-between mt-3'>
                  {
                    readMarkRes?.data?.filter((itemc) => itemc.circularId === item.id && itemc.userId === userRes.data.id).length <= 0 && (
                      <button className="btn btn-success rounded-pill px-3 text-white ms-auto" onClick={() => markRead(item.id)}>
                        Mark as read
                      </button>
                    )
                  }

                </div> */}

                  {/* Collapsible content */}
                  <div className="collapse mt-3 " id={`collapseContent${i}`}>
                    <div
                      className="card card-body "
                      dangerouslySetInnerHTML={{ __html: item.content }}
                    />
                    <div className="d-flex justify-content-between align-items-center mt-3 px-3">
                      <Link
                        className="btn btn-secondary rounded-pill"
                        target="_blank"
                        to={item.link}
                      >
                        Download Circular
                      </Link>
                      <CircularDeleteModal
                        id={item.id}
                        refetch={response.refetch}
                      />
                      <Link
                        className="btn btn-secondary px-5 rounded-pill"
                        to={"" + item.id}
                      >
                        Edit
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="col-4 h-100 ">
          <form
            className=" h-100 "
            onSubmit={onSubmit}
            noValidate
          >
            <div className="bg-white h-100 overflow-hidden bg-danger rounded-4  pb-5">
              <div className=" text-center d-flex align-items-center justify-content-center mx-4">
                <div className="rounded-bottom-4  px-3 py-2 bg-primary text-white">
                  Create new Circular
                </div>
              </div>

              <div className="p-4  h-100 overflow-auto ">
                <Input
                  name={"Circular Title"}
                  containerClass={"mb-3"}
                  required
                />

                <FormattedInput />

                <Select
                  labelName={"Type"}
                  options={["General", "User-specific"]}
                  value={select}
                  setValue={(value) => setSelect(value)}
                />

                <div className="form-check">
                  <div className="row row-cols-3">
                    <div className="col">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={kanyaGurukul}
                        onChange={(e) => setKanyaGurukul(e.target.checked)}
                        id="c2"
                        name="kanyagurukul"
                      />
                      <label className="form-check-label fs-13" htmlFor="c2">
                        Kanya gurukul
                      </label>
                    </div>
                    <div className="col">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={schoolManager}
                        onChange={(e) => setSchoolManager(e.target.checked)}
                        id="c3"
                        name="schoolmanager"
                      />
                      <label className="form-check-label fs-13" htmlFor="c3">
                        School manager
                      </label>
                    </div>

                    <div className="col">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={hostelManager}
                        onChange={(e) => setHostelManager(e.target.checked)}
                        id="c5"
                        name="hostelmanager"
                      />
                      <label className="form-check-label fs-13" htmlFor="c5">
                        Hostel manager
                      </label>
                    </div>
                    <div className="col">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={principal}
                        onChange={(e) => setPrincipal(e.target.checked)}
                        id="c6"
                        name="principal"
                      />
                      <label className="form-check-label fs-13" htmlFor="c6">
                        Principal
                      </label>
                    </div>
                  </div>
                </div>

                <Input
                  name={"Date"}
                  type={"date"}
                  containerClass={"my-3"}
                  required
                />
                <Input name={"Attachment"} containerClass={"my-3"} />
                <Button
                  className="btn btn-primary rounded-pill"
                  res={createCircularRes}
                  loadingLabel={"Creating"}
                >
                  Post the Circular
                </Button>
              </div>

              {/* <button className="btn btn-primary rounded-pill my-3" type="submit">Post the Circular</button> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Circulars;
