import React from 'react'
import Input from '../../components/form/Input'
import Button from '../../components/form/Button'
import { useCreateCategoryMutation } from '../../services/categorySlice';

const CreateCategoryModal = ({categoryRes, type }) => {

    const [createCategory, createCategoryRes] = useCreateCategoryMutation();

    const modalId = "createCategoryModal"

    const onCategorySubmit = (event) => {
        event.preventDefault();
        const form = event.target;
        if (form.checkValidity()) {
            createCategory({
                title: event.target["Category Title"].value,
                type: type,
            }).then((res) => {
                if (!res.error) {
                    categoryRes.refetch();
                }
            });
        }
        form.classList.add("was-validated");
    };

    return (

        <>
            <button type="button" class="btn btn-primary rounded-pill" data-bs-toggle="modal" data-bs-target={"#" + modalId}>
                Create
            </button>

            <div class="modal fade" id={modalId} tabindex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">
                <div class="modal-dialog p-0 modal-dialog-centered">
                    <div class="modal-content  p-0">
                        <div class="modal-body m-0 p-0">
                            {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            <div className="">
                                <div className="bg-primary text-white px-5 py-2 rounded-bottom-4 position-absolute mx-4 ">
                                    Create new Category
                                </div>

                                <form className="" onSubmit={onCategorySubmit} noValidate>
                                    <div className="bg-white rounded-4 px-5 pt-5">
                                        <Input name={"Category Title"} required />
                                        <Button
                                            className="btn btn-primary px-5 rounded-pill my-4"
                                            res={createCategoryRes}
                                            loadingLabel={"Creating"}
                                        >
                                            Create
                                        </Button>
                                        {/* <button type='submit' className="btn btn-primary px-5 rounded-pill my-4">Create</button> */}
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>

    )
}

export default CreateCategoryModal