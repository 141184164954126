import { Route, Routes, } from 'react-router-dom';
import { useEffect, useState } from 'react';


import "./assets/scss/bootstrap.scss"
import "./assets/css/global.css"
import "./assets/css/fonts.css"
import "./assets/css/scrollbar.css"
import "./assets/css/settings.css"

import Layout from './components/layout/Layout';
import Dashboard from './pages/Dashboard';
import Circulars from './pages/circulars/Circulars';
import School from './pages/school/School';
import Hostel from './pages/hostel/Hostel';
import Education from './pages/education/Education';
import KanyaGurukul from './pages/kanyaGurukul/KanyaGurukul';
import Archives from './pages/archives/Archives';
import Downloadables from './pages/downloadables/Downloadables';
import Support from './pages/support/Support';
import ManageUsers from './pages/mangeUser/ManageUsers';
import Create from './pages/mangeUser/Create';
import CreateArchive from './pages/archives/CreateArchive';
import CreateDownloadables from './pages/downloadables/CreateDownloadables';
import { useSelector } from 'react-redux';
import EditDownloadables from './pages/downloadables/EditDownloadables';
import EditArchive from './pages/archives/EditArchive';
import EditPolicy from './pages/school/EditPolicy';
import EditCircular from './pages/circulars/EditCircular';
import SupportAction from './pages/support/SupportAction';
import CreateNotice from './pages/notice/CreateNotice';
import EditNotice from './pages/notice/EditNotice';
import Settings from './pages/settings/Settings';
import ViewPolicy from './pages/ViewPolicy';
import Notices from './pages/notice/Notices';
import SettingsV1 from './pages/settings/SettingsV1';
import SingleSignOn from './pages/signIn/SingleSignOn';
import SignInByToken from './pages/signIn/SignInByToken';
import SignIn from './pages/signIn/SignIn';
import SignInHandler from './pages/signIn/SignInHandler';



function App() {

  // const [signIn, setSignIn] = useState(false)
  // const isSignedIn = useSelector(state => state.apiSlice.mutations?.signIn?.data?.token);
  const isSignedIn = useSelector(state => state.auth.authToken);

  useEffect(() => { import('bootstrap') }, [])


  return (

    <Routes>

      {
        isSignedIn
          ?
          <Route path='/' element={<Layout />}>

            <Route index element={<Dashboard />} />
            <Route path='singleSignOn' element={<SingleSignOn />} />
            <Route path='token/:token' element={<SignInByToken />} />

            {/* <Route path='Circulars' element={<Circulars />} /> */}
            <Route path='Circulars' >
              <Route index element={<Circulars />} />
              <Route path=':id' element={<EditCircular />} />
            </Route>

            <Route path='School' >
              <Route index element={<School />} />
              <Route path='edit/:id' element={<EditPolicy />} />
              <Route path=':id' element={<ViewPolicy />} />
            </Route>

            <Route path='Hostel' >
              <Route index element={<Hostel />} />
              <Route path='edit/:id' element={<EditPolicy />} />
              <Route path=':id' element={<ViewPolicy />} />
            </Route>

            <Route path='Education' >
              <Route index element={<Education />} />
              <Route path='edit/:id' element={<EditPolicy />} />
              <Route path=':id' element={<ViewPolicy />} />
            </Route>

            <Route path='KanyaGurukul' >
              <Route index element={<KanyaGurukul />} />
              <Route path='edit/:id' element={<EditPolicy />} />
              <Route path=':id' element={<ViewPolicy />} />
            </Route>

            <Route path='Downloadables' >
              <Route index element={<Downloadables />} />
              <Route path='create' element={<CreateDownloadables />} />
              <Route path=':id' element={<EditDownloadables />} />
            </Route>

            <Route path='Archives' >
              <Route index element={<Archives />} />
              <Route path='create' element={<CreateArchive />} />
              <Route path=':id' element={<EditArchive />} />
            </Route>

            <Route path='Support' >
              <Route index element={<Support />} />
            </Route>

            <Route path='notice' >
              <Route index element={<Notices />} />
              <Route path='create' element={<CreateNotice />} />
              <Route path=':id' element={<EditNotice />} />
            </Route>

            <Route path='ManageUsers' >
              <Route index element={<ManageUsers />} />
              <Route path='create' element={<Create />} />
            </Route>

            <Route path='settingV1' element={<SettingsV1 />} />
            <Route path='settings' element={<Settings />} />

            <Route path='*' element={<Dashboard />} />


          </Route>
          :
          <Route path='/'>
            <Route index element={<SignIn />} />
            <Route path='token/:token' element={<SignInByToken />} />
            <Route path='*' element={<SignInHandler />} />
          </Route>
      }

    </Routes>

  );

}

export default App;